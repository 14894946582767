import React from 'react'
import { useSelector } from 'react-redux'
import Txndatatablecom from '../../Components/Txndatatablecom'

const Pendingtxn = () => {

  const txnData = useSelector((state)=>state.txnReducer).filter((val)=>val.type!=='Paid' && val.status=='Pending')

  return (
    <div>

<Txndatatablecom data={txnData}/>

    </div>
  )
}

export default Pendingtxn