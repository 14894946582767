import React from 'react'
import { useState } from 'react'
import {useSelector} from 'react-redux'
import Pagination from '../../Components/Pagination'
import Calendarmy from '../../Components/Shortcom/Calendarmy'

import Txndatatablecom from '../../Components/Txndatatablecom'

const Maintxn = () => {
  const txnData = useSelector((state)=>state.txnReducer)



 

  const [startDate, setStartDate] = useState(null)
  const mydate = new Date(startDate)
  const todate = mydate.getDate()
  let month = mydate.getMonth()
  month = month+1
  const year = mydate.getFullYear()
  const finaldate = `${year}-${month<10?`0${month}`:month}-${todate<10?`0${todate}`:todate}`

  const [findid, setfindid] = useState('')

  // console.log(todate)
  
    const filldata = txnData.filter((x) => {
      return startDate !==null? x.createdAt?.split('T')[0]===finaldate : findid !== "" ? x.id.toString().includes(findid) || x.userid.toString().includes(findid) || x.amount.toString().includes(findid) || x.txnname.toLowerCase().includes(findid) || x.status.includes(findid) : x
    })

 

  return (
    <div>

<div class="search" style={{ display: 'flex', width: '70%' }}>

<label>
  <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
</label>

<div style={{cursor:'pointer',zIndex:8}}>
          Date
          <Calendarmy value={startDate} onChange={(e)=>setStartDate(e)}/>
        </div>

</div>

        <Txndatatablecom data={filldata}/>

        {/* <Pagination totalData={txnData.length} perPage={userPerPage} paginate={paginate}/> */}


    </div>
  )
}

export default Maintxn