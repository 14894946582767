import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-rainbow-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';




const Popupnoti = ({ title, msg, success, show, direction, dispatchfun }) => {

    const dispatch = useDispatch()
    const [open, setopen] = useState(true)

    const navigate = useNavigate()

    const handlebtnclk = () => {
        dispatchfun && dispatch(dispatchfun())
        setopen(false)

        navigate(direction)
        // navigate(0)
    }

    const firstinmi = () => {
        setopen(show)

    }

    useEffect(() => {
        firstinmi()
    }, [show])


    return (
        <div>

            <Modal id="modal-1" isOpen={open} hideCloseButton={true} footer={

                <div className="flex justify-end">
                    <Button onClick={handlebtnclk} label={'OK'} variant="brand" />

                </div>
            }>
                <div className='flex flex-col items-center'>
                    <img
                        src={success ? "https://cdn.dribbble.com/users/251873/screenshots/9289747/media/6ddd0b400fbab6d5fa72d73df503f330.gif" : "https://cdn.dribbble.com/users/251873/screenshots/9288094/media/a1c2f89065f68e1b2b5dcb66bdb9beb1.gif"}
                        className="rainbow-p-around_xx-large rainbow-m_auto rainbow-align-content_center"
                        alt="landscape with rainbows, birds and colorful balloons"
                        style={{ width: 150, height: 110 }}
                    />
                    <p>{msg}</p>
                </div>



            </Modal>
        </div>
    )
}

export default Popupnoti