import React from 'react'
import Select from 'react-select'

const Selcetlat = ({label,value,onChange,data,name}) => {
  return (
    <div class="mb-6">
    <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label}</label>
    <Select options={data} value={value} onChange={onChange} name={name}/>
</div>
  )
}

export default Selcetlat