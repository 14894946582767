import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Btnwithicon from '../../Components/Btnwithicon'

const Mainclone = () => {
    const navigate = useNavigate()


    const AddofferBtnClk = ()=>{
        navigate('/addcloned')
    }
  return (
    <div>

<div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

<Btnwithicon color={'#2a2185'} title={'ADD CLONED'} icon={faCirclePlus} onClick={AddofferBtnClk} />
</div>
    </div>
  )
}

export default Mainclone