import React from 'react'
import { useNavigate } from 'react-router-dom'
import Btnwithicon from '../../Components/Btnwithicon'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Promotable from '../../Components/Tablesdata/Promotable';
import { useSelector } from 'react-redux';


const Mainpmcode = () => {

  const promoData = useSelector((state) => state.promoReducer)


    const navigate = useNavigate()

    const AddofferBtnClk = () => {
      navigate('/addpmcode')
  
    }
  return (
    <div>

<div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

<Btnwithicon color={'#2a2185'} title={'ADD PROMOCODE'} icon={faCirclePlus} onClick={AddofferBtnClk} />


</div>

<Promotable data={promoData}/>
    </div>
  )
}

export default Mainpmcode