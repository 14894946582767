

import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faIndianRupeeSign,faXmark,faEye,faEdit,faTrash,faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Deletenoti from '../Deletenoti';
import gpeapi from '../../api/gpeapi';


const Refertable = ({options,data}) => {
  const token = localStorage.getItem('token')

  const navigate = useNavigate()


  const getdatevia = (date)=>{
    const mydate = new Date(date)
    const todate = mydate.getDate()
    const month = mydate.getMonth()
    const year = mydate.getFullYear()

    return `${todate}-${month}-${year}`
  }


    
  return (
    <div className='container'>
      <br />

      <div className='d-flex justify-content-between'>
        


        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table  sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
           
              <TableCell ><strong>UserID</strong></TableCell>
              <TableCell><strong>Name </strong></TableCell>
              <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Wallet</strong></TableCell>
              
              
            </TableRow>
          </TableHead>
          <TableBody>
            { data && data.map((row,i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
              
                <TableCell >{row.email}</TableCell>
                <TableCell >{row.wallet}₹</TableCell>
                {/* <TableCell >{getdatevia(row.createdAt)}</TableCell>
                <TableCell style={{fontSize:10}}>{row.clickid}</TableCell> */}
               
                
                {/* <TableCell  style={{display:'flex',justifyContent:'space-evenly',border:0}}>
                    <FontAwesomeIcon className='usertablicon'  color='yellowgreen' icon={faEye}  />
                    <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={()=>handleditclk(row.id)} icon={faEdit} />
                    <FontAwesomeIcon className='usertablicon' color='red' onClick={()=>handledeletclk(row.id)} icon={faTrash} />
                   
                    </TableCell> */}
                
                



              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Refertable