import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedbanner, fethselectedbasic, fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethbasic } from '../../redux/actions/basicaction'

const Addbasic = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state)=>state.selectedReducer)
  const state6 = useSelector((state)=>state.basicReducer)

  const token = localStorage.getItem('token')

const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    appname:'',
    icon:'',
    signupamt:'',
    referamt:'',
    withdrawamt:'',
    appdes:'',
    appurl:'',
    version:'',
    telegramurl:'',
    privacypolicy:'',
    aboutus:''
    
    
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}




const callingAPI = ()=>{
  dispatch(fethselectedbasic())
}

const updateForm = async ()=>{
        
  const userData = await state6.filter((val)=>val.id==1)
  
  userData.map((val)=>setmydta(val))

 
 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}


const handlBtnClk = ()=>{
   

    gpeapi.put(`/updatebasic`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    
  // console.log(mydta)
  }









// console.log(state5[0])
const match = state6[0]?.id==1

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])






  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'App Name'} placeholder={'Please Enter App Name'} name='appname' value={mydta.appname} onChange={handleChnage}  />
      
        <Inputcom label={'App Url'} placeholder={'Please Enter App URL'} name='appurl' value={mydta.appurl} onChange={handleChnage}/>

        <Inputcom label={'App IMG Url'} placeholder={'Please Enter App IMG URL'} name='icon' value={mydta.icon} onChange={handleChnage}/>

        {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.icon?mydta.icon:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:110 , height:100,padding:3}}/>
          </div>}


          <Inputcom label={'App Telegram Url'} placeholder={'Please Enter App Telegram URL'} name='telegramurl' value={mydta.telegramurl} onChange={handleChnage}/>

          <Inputcom label={'App Description'} placeholder={'Please Enter App Description'} name='appdes' value={mydta.appdes} onChange={handleChnage}/>
          


          <Inputcom label={'Signup AMT'} placeholder={'Please Enter Signup AMT'} name='signupamt' value={mydta.signupamt} onChange={handleChnage}/>

          <Inputcom label={'Refer AMT'} placeholder={'Please Enter Refer AMT'} name='referamt' value={mydta.referamt} onChange={handleChnage}/>

          <Inputcom label={'Withdrwa AMT'} placeholder={'Please Enter Withdrwa AMT'} name='withdrawamt' value={mydta.withdrawamt} onChange={handleChnage}/>

          <Inputcom label={'App Version'} placeholder={'Please Enter App Version'} name='version' value={mydta.version} onChange={handleChnage}/>

          <Textareacom row={15} label='About Us' placeholder='Please Enter About us' name='aboutus' value={mydta.aboutus} onChange={handleChnage} />
          <Textareacom row={15} label='Privacy Policy' placeholder='Please Enter Privacy Policy' name='privacypolicy' value={mydta.privacypolicy} onChange={handleChnage} />




        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        {/* <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/> */}

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/basic'} dispatchfun={fethbasic}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={'UPDATE BASIC'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>

        


        



    </div>
  )
}

export default Addbasic


