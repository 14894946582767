import { ADD_TXN, SELECTED_BANNER, SELECTED_CATEGORY, SELECTED_DEAL, SELECTED_OFFER, SELECTED_PROMO, SELECTED_STORE, SELECTED_TXN, SELECTED_USER, SET_PERUSER, SET_TXN, SET_WITXN, UPDATE_SELECTED_TXN } from "../actiontypes/usertypes";

export const productsInitialState = [];

const selectedReducer = (state = productsInitialState, action) => {
  switch (action.type) {
    case SELECTED_TXN:
      return [action.payload];

    case SELECTED_OFFER:
      return [action.payload]

    case SELECTED_USER:
      return [action.payload]

    case SELECTED_BANNER:
      return [action.payload]

    case SELECTED_STORE:
      return [action.payload]

    case SELECTED_CATEGORY:
      return [action.payload]

      case SELECTED_DEAL:
      return [action.payload]

      case SELECTED_PROMO:
      return [action.payload]

    default:
      return state;
  }
};

export default selectedReducer;