import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Mybtn from '../../Components/Mybtn'
import Selectcom from '../../Components/Selectcom'
import { fethOffers } from '../../redux/actions/offeraction'
import ReactStars from 'react-stars'
import { Card, ButtonGroup, ButtonIcon, Rating } from 'react-rainbow-components';

const Viewbooking = () => {

  const token = localStorage.getItem('token')
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { id } = useParams()
  const [toggle, settoggle] = useState(false)


  const bookingData = useSelector((state) => state.offerReducer).filter((val) => val.txnid == id)[0]

  // console.log(bookingData)
  const convertDate = (date) => {
    const mydta = new Date(date);
    const year = mydta.getFullYear();
    const month = mydta.getMonth();
    const day = mydta.getDate()
    const gday = day < 10 ? `0${day}` : day
    var hours = mydta.getHours();
    var minutes = mydta.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${gday}-${month + 1}-${year} ${hours}:${minutes} ${ampm}`
  }

  const [mydta, setmydta] = useState({
    partnerid: bookingData?.partnerid,
    userid: bookingData?.userid,
    status: bookingData?.status,
    isaccepted: bookingData?.isaccepted,

  })

  const handleChnage = (e) => {
    const name = e.target.name
    const value = e.target.value

    setmydta({ ...mydta, [name]: value })
  }

  const handleupdateStatus = async () => {
    const res = await gpeapi.put(`/updatebooking/${bookingData?.id}`, mydta, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => {
      alert('Update Done')
      dispatch(fethOffers())

    }).catch((e) => {
      alert('Some error on updating')
    })
    // console.log(mydta)
  }


  // setTimeout(() => {
  //   console.log('after 3 sec')
  // }, 3000);



  return (
    <div className={toggle ? "main active" : "main"} style={{ margin: 20, backgroundColor: '#ecf0f5' }}>

      <div onClick={() => settoggle(!toggle)} class="">
        <ion-icon name="menu-outline"></ion-icon>
      </div>

      <div style={{ margin: '5px 15px 0px 10px', border: '1px solid #e23744', backgroundColor: 'white', borderRadius: 8 }}>

        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '10px 0px' }}>
          <p style={{ fontSize: 19 }}>ID: {bookingData?.id}</p>
          <p style={{ fontSize: 19 }}>OrderID: {bookingData?.orderid}</p>
          <p style={{ fontSize: 19 }}>TXN ID: {bookingData?.txnid}</p>
        </div>


        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '10px 0px' }}>
          <p style={{ fontSize: 19 }}>Category: {bookingData?.type}</p>
          <p style={{ fontSize: 19 }}>Price: {bookingData?.orderamount}₹</p>
          <p style={{ fontSize: 19 }}>Date: {convertDate(bookingData?.createdAt)}</p>
        </div>


        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', margin: '10px 0px' }}>
          <p style={{ fontSize: 19 }}>Status: {bookingData?.status}</p>
          <p style={{ fontSize: 19 }}>IsAccepted: {bookingData?.isaccepted}</p>
          <p style={{ fontSize: 19 }}>WorkDone: {bookingData?.workdone ? 'YES' : 'NO'}</p>

        </div>
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className='profilecard1'>
          <p style={{ fontSize: 19 }}>User Info</p>
          <p>userID: {bookingData?.userid}</p>
          <p>userMobile: {bookingData?.usermobile}</p>
          {/* <p>userID: {bookingData?.userid}</p> */}

        </div>

        <div className='profilecard1'>
          <p style={{ fontSize: 19 }}>Partner Info</p>
          <p>userID: {bookingData?.partnerid}</p>
          <p>userMobile: {bookingData?.partnermobile}</p>
        </div>

        <div className='profilecard1'>
          <p style={{ fontSize: 19, margin: '10px 0px' }}>Chnage Status/isaccepted</p>
          <Selectcom label={'status'} data={['success', 'cancel', 'Pending']} name='status' onChange={handleChnage} value={mydta.status} />
          <Selectcom label={'is Accepted'} data={['Accepted', 'Pending', 'cancel']} name='isaccepted' onChange={handleChnage} value={mydta.isaccepted} />

          <Mybtn title={'UPDATE'} color={'white'} onClick={handleupdateStatus} />


        </div>

      </div>


      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div className='profilecard1'>
          <p style={{ fontSize: 19 }}>Map Direction</p>

          <div>

          </div>


        </div>

        {
          bookingData?.workdone && <div className='profilecard1'>
          <p style={{ fontSize: 19,margin:'10px 0px' }}>Review</p>

          <div style={{display:'block',justifyContent:'center'}}>
            
            

            <Rating readOnly value={bookingData?.stars}/>

            <p>{bookingData?.reviewcomment}</p>

            <img style={{height:300,width:300,margin:'auto'}} src={bookingData?.workimg}/>
          </div>


        </div>
        }

      </div>






    </div>
  )
}

export default Viewbooking