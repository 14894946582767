import React from 'react'
import DashCard from '../../Components/DashCard'

const Dashuper = ({ userdata, offerdata, bannerData, todayclick, yesclicks, stores, todayuser, yesuser, activeuser, totalcb, approvedcb, pendingcb, rejectcb, withdrwapending, withdone,totalbooking,totaluser,totalpartner,pendingpartners }) => {

    // console.log(withdrwapending)

    return (
        <>
            <DashCard number={userdata.length} title='Total Users/Partners' icon={'person-outline'} />
            <DashCard number={totaluser} title='Total Users' icon={'person-outline'} />
            <DashCard number={totalpartner} title='Total Partners' icon={'person-outline'} />
            <DashCard number={pendingpartners} title='Pending Partners' icon={'person-outline'} />
            {/* <DashCard number={offerdata.length} title='Total Offers' icon={'storefront-outline'} /> */}
            {/* <DashCard number={bannerData.length} title='Total Banners' icon={'images-outline'} /> */}
            {/* <DashCard number={stores.length} title='Total Stores' icon={'briefcase-outline'} /> */}
            
            <DashCard number={todayuser.length} title='Today Users/Partner' icon={'person-outline'} />
            <DashCard number={yesuser.length} title='Yesterday Users/Partner' icon={'person-outline'} />
            {/* <DashCard number={activeuser.length} title='Active on App' icon={'person-outline'} /> */}
            <DashCard number={`${totalcb ? totalcb : 0}₹`} title='Total TXN amt' icon={'newspaper-outline'} />

            <DashCard number={`${totalbooking ? totalbooking : 0}`} title='Total Bookings' icon={'bookmark-outline'} />
            <DashCard number={`${approvedcb ? approvedcb : 0}`} title='Success Bookings' icon={'bookmark-outline'} />
            <DashCard number={`${pendingcb ? pendingcb : 0}`} title='Pending Bookings' icon={'bookmark-outline'} />
            <DashCard number={`${rejectcb ? rejectcb : 0}`} title='Cancel Bookings' icon={'bookmark-outline'} />
            {/* <DashCard number={`${withdone ? withdone : 0}₹`} title='Withdrawal Successful' icon={'person-outline'} /> */}
            {/* <DashCard number={`${withdrwapending ? withdrwapending : 0}₹`} title='Withdrawal Pending' icon={'person-outline'} /> */}

            <DashCard number={todayclick.length} title='Today Views' icon={'disc-outline'} />
            <DashCard number={yesclicks.length} title='Yesterday Views' icon={'disc-outline'} />

        </>


    )
}

export default Dashuper