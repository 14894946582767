import { SET_CLONED } from "../actiontypes/usertypes";

export const productsInitialState = [];

const clonedReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_CLONED:
        return [...action.payload];

        // case ADD_TXN:
        //     return [action.payload]

            // case SET_PERUSER:
            // return [action.payload]
      default:
        return state;
    }
  };
  
  export default clonedReducer;