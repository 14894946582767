
import gpeapi from "../../api/gpeapi";
import { SET_CATEGORYS} from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethcategory = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getcategory',{headers:{
            "Authorization":`Beaer ${token}`
        }})




        const fillterdtoNew = res.data?.offerlist?.sort((a,b)=>b.id-a.id)
       


      
        dispatch(setCategorys(fillterdtoNew));
        // dispatch(updateOffer(res1.data));
       
    }
}






export const setCategorys = (data = null) => {
    if (data) {
        return {
            type: SET_CATEGORYS,
            payload: data,
        };
    }

    return {
        type: SET_CATEGORYS,
        payload: [],
    };
};






