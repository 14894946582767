import React, { useEffect, useState } from 'react'

const SimpleinpuSelect = ({
  placeholder,
  value,
  label,
  options,
  onSearch,
  onSelect,
  optionKey = "label",
  optionCount = 5,
  noOptionText = "No Items"
}) => {
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState("");
  const [allOption, setAllOption] = useState(options || []);

  useEffect(() => {
    setAllOption(options);
  }, [options]);

  const selectHandle = (val) => {
    setSearchText("");
    setSelected(val[optionKey]);
    if (onSelect) {
      onSelect(val);
      return;
    }
  };

  const handleChange = ({ target }) => {
    setSearchText(target.value);
    if (onSearch) {
      onSearch(target.value, (data) => setAllOption(data));
      return;
    }

    let tempOptions = [...options];
    tempOptions = tempOptions.filter((obj) =>
      obj[optionKey]?.toLowerCase().includes(target.value?.toLowerCase())
    );
    setAllOption(tempOptions);
  };

 

  return (
    <div className='autoComplete mb-6' style={{position:'relative'}}>
      <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label}</label>
      <input
        className='inputBox'
        onFocus={() => {
          setSelected("");
        }}
        placeholder={placeholder}
        value={selected || searchText || value}
        onChange={handleChange}
        style={{
          borderBottomLeftRadius: searchText ? 0 : "",
          borderBottomRightRadius: searchText ? 0 : ""
        }}
      />
      <div
        className="dropdown"
        style={{
          display: searchText ? "flex" : "none",
          // oneOption Height * count - 1st borderTop (1px)
          maxHeight: `${35 * optionCount - 1}px`
        }}
      >

        
        {!allOption.length ? (
          <div> {noOptionText} </div>
        ) : (
          allOption.map((option, index) => (
            <div key={`${index}`} onClick={() => selectHandle(option)}>
              {option[optionKey]}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export default SimpleinpuSelect;
