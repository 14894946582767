import gpeapi from "../../api/gpeapi";
import { SELECTED_BASIC, SELECTED_CATEGORY, SELECTED_DEAL, SELECTED_OFFER, SELECTED_PROMO, SELECTED_STORE, SELECTED_TXN, SELECTED_USER, UPDATE_SELECTED_TXN } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethselectedtxn = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/txnhistory/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedtxn(res.data.mydta));
       
    }
}

export const fethselectedoffer = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/getbooking/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedoffer(res.data.mydta));
       
    }
}

export const fethselectedpromo = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/getpmcode/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedpromo(res.data.mydta));
       
    }
}





export const fethselectedstore = (id)=>{
    return async function(dispatch){

        // const res = await gpeapi.get(`/getstore/${id}`,{headers:{
        //     "Authorization":`Beaer ${token}`
        // }})

        // dispatch(setSelectedstore(res.data.mydta));
       
    }
}

export const fethselecteddeal = (id)=>{
    return async function(dispatch){

        // const res = await gpeapi.get(`/getdeal/${id}`,{headers:{
        //     "Authorization":`Beaer ${token}`
        // }})

        // dispatch(setSelecteddeal(res.data.mydta));
       
    }
}


export const fethselectedcategory = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/getcategory/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedcategory(res.data.mydta));
       
    }
}

export const fethselecteduser = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/users/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelecteduser(res.data.Userdata));
       
    }
}

export const fethselectedbanner = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/getbanner/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedbanner(res.data.mydta));
       
    }
}


export const fethselectedbasic = (id)=>{
    return async function(dispatch){

        const res = await gpeapi.get(`/getbasicdet`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setSelectedbasic(res.data.signupamt));
       
    }
}








export const updateselectedtxn = (id,data)=>{
    return async function(dispatch){

        const res = await gpeapi.put(`/updatetxnhistory/${id}`,data,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setupdateSelectedtxn(res.data));
       
    }
}


export const setSelectedbasic= (data = null) => {
    if (data) {
        return {
            type: SELECTED_BASIC,
            payload: data,
        };
    }

    return {
        type: SELECTED_BASIC,
        payload: [],
    };
};


export const setSelectedbanner= (data = null) => {
    if (data) {
        return {
            type: SELECTED_USER,
            payload: data,
        };
    }

    return {
        type: SELECTED_USER,
        payload: [],
    };
};




export const setSelecteduser= (data = null) => {
    if (data) {
        return {
            type: SELECTED_USER,
            payload: data,
        };
    }

    return {
        type: SELECTED_USER,
        payload: [],
    };
};


export const setSelectedtxn = (data = null) => {
    if (data) {
        return {
            type: SELECTED_TXN,
            payload: data,
        };
    }

    return {
        type: SELECTED_TXN,
        payload: [],
    };
};

export const setSelectedoffer = (data = null) => {
    if (data) {
        return {
            type: SELECTED_OFFER,
            payload: data,
        };
    }

    return {
        type: SELECTED_OFFER,
        payload: [],
    };
};

export const setSelectedpromo = (data = null) => {
    if (data) {
        return {
            type: SELECTED_PROMO,
            payload: data,
        };
    }

    return {
        type: SELECTED_PROMO,
        payload: [],
    };
};

export const setSelectedstore = (data = null) => {
    if (data) {
        return {
            type: SELECTED_STORE,
            payload: data,
        };
    }

    return {
        type: SELECTED_STORE,
        payload: [],
    };
};

export const setSelecteddeal = (data = null) => {
    if (data) {
        return {
            type: SELECTED_DEAL,
            payload: data,
        };
    }

    return {
        type: SELECTED_DEAL,
        payload: [],
    };
};


export const setSelectedcategory = (data = null) => {
    if (data) {
        return {
            type: SELECTED_CATEGORY,
            payload: data,
        };
    }

    return {
        type: SELECTED_CATEGORY,
        payload: [],
    };
};


export const setupdateSelectedtxn = (data = null) => {
    if (data) {
        return {
            type: UPDATE_SELECTED_TXN,
            payload: data,
        };
    }

    return {
        type: UPDATE_SELECTED_TXN,
        payload: [],
    };
};


