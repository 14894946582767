
import gpeapi from "../../api/gpeapi";
import { SET_PROMO} from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethPromocode = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getpmcode',{headers:{
            "Authorization":`Beaer ${token}`
        }})

       
        const fillterdtoNew = res.data?.offerlist?.sort((a,b)=>b.id-a.id)


      
        dispatch(setPromo(fillterdtoNew));
        // dispatch(updateOffer(res1.data));
       
    }
}






export const setPromo = (data = null) => {
    if (data) {
        return {
            type: SET_PROMO,
            payload: data,
        };
    }

    return {
        type: SET_PROMO,
        payload: [],
    };
};




