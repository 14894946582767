
import { faCirclePlus, faRemove, faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import { useDispatch, useSelector } from 'react-redux'
import { fethselectedoffer, fethselectedstore } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Mybtn from '../../Components/Mybtn'
import Select from 'react-select'
import Latselct from '../../Components/Latselct'
import { fethstores } from '../../redux/actions/storeaction'

const Addstore = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const state5 = useSelector((state) => state.selectedReducer)
    const categoryData = useSelector((state) => state.categoryReducer)



    const token = localStorage.getItem('token')

    const [notidata, setnotidata] = useState({
        msg: '',
        success: '',
        show: false
    })
    const options = []

    const updateoption = []

    const [mycategory, setmycategory] = useState([])

    const [formValues, setFormValues] = useState([{ rate: "", description: "" }])

    const [mydta, setmydta] = useState({
        name: '',
        icon: '',
        cashbackrate: '',
        cashbacktype: '',
        allcashbackrates: '',
        sortdes: '',
        fulldes: '',
        url: '',
        category: '',
        isactive: '',
        ispopular: '',
        missing: '',
        keypoints: '',
        tracktime: '',
        confirmtime: '',
        expiredat: '',
        cashbackvalid: '',
        cashbacktypeon: '',
        keywords: '',
        istrending:'',
        formurl:''
    })

    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }

    const handleChnagekeywords = (e) => {
        const newkewords = e.target.value.split(',')
        setmydta({ ...mydta, keywords: newkewords })
    }




    const callingAPI = () => {
        dispatch(fethselectedstore(id))
    }

    const updateForm = async () => {

        const userData = await state5.filter((val) => val.id == id)

        // console.log(userData[0].allcashbackrates.length)
        
        if(userData[0].allcashbackrates.length==0){
            setFormValues([{ rate: "", description: "" }])
        }else{

            userData.map((val) => setFormValues(val.allcashbackrates))
        }
        userData.map((val) => setmydta(val))





        // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

    }

    const categorydta = mydta.category
    for (let i = 0; i < categorydta.length; i++) {
        updateoption.push({ label: categorydta[i], value: categorydta[i] })
        // console.log({label:categorydta[i],value:categorydta[i]})

    }

    // console.log(updateoption)


    const handlBtnClk = () => {


        id ? gpeapi.put(`/updatstore/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))
            : gpeapi.post('/addstore', mydta, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))

        // console.log(mydta)
    }









    // console.log(state5[0])
    const match = state5[0]?.id == id

    useEffect(() => {
        id && callingAPI()
        updateForm()
    }, [match, id])



    const handleChnagenew = (e, i) => {
        const value = e.target.value;
        const name = e.target.name;
        const list = [...formValues]
        list[i][name] = value
        setFormValues(list)



    }

    const handleplushicon = () => {
        setFormValues([...formValues, { rate: "", description: "" }])
    }

    const handleremoveicon = (i) => {
        const list = [...formValues];
        list.splice(i, 1)
        setFormValues(list)


    }

    const cashratedataadd = () => {
        setmydta({ ...mydta, allcashbackrates: formValues })
        alert('added done')
    }



    const setcategoryyy = async () => {
        const allCategoryName = await categoryData.map((val) => options.push({ label: val.name, value: val.name }))


    }

    useEffect(() => {
        setcategoryyy()
    }, [options])



    const handlecategoryselect = (e) => {

        const categoryev = e.map((val) => val.value)
        setmydta({ ...mydta, category: categoryev })
    }






    return (
        <div style={{ width: '90%', marginTop: 30 }}>



            <Inputcom label={'Store Name'} placeholder={'Please Enter Store Name'} name='name' value={mydta.name} onChange={handleChnage} />

            <Selectcom label={'Cashback Type'} data={['FLAT', 'UPTO']} name='cashbacktype' onChange={handleChnage} value={mydta.cashbacktype} />

            <Selectcom label={'Cashback Type ₹/% '} data={['₹', '%']} name='cashbacktypeon' onChange={handleChnage} value={mydta.cashbacktypeon} />

            <Selectcom label={'Cashback is Avaliable'} data={['true', 'false']} name='cashbackvalid' onChange={handleChnage} value={mydta.cashbackvalid} />

            <Inputcom label={'Store Cashback'} placeholder={'Please Enter Store Cashback'} name='cashbackrate' value={mydta.cashbackrate} onChange={handleChnage} />



            <div style={{ border: '2px solid #e65b32', borderRadius: 8 }}>
                {formValues && formValues.map((val, i) => (
                    <div key={i} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}><Inputcom label={'Cashback Ratess'} placeholder={'Please Enter Store Cashback Rates'} name='rate' value={val.rate} onChange={(e) => handleChnagenew(e, i)} />

                        <Inputcom label={'Cashback Description'} placeholder={'Please Enter Store Cashback Description'} name='description' value={val.description} onChange={(e) => handleChnagenew(e, i)} />

                        {
                            formValues.length !== 1 && <FontAwesomeIcon onClick={() => handleremoveicon(i)} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faRemove} />
                        }
                        {
                            formValues.length - 1 == i && <FontAwesomeIcon onClick={handleplushicon} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faCirclePlus} />
                        }

                    </div>
                ))}
            </div>

            <Mybtn title={'Add'} onClick={cashratedataadd} />





            <Inputcom label={'Store Url'} placeholder={'Please Enter Store URL'} name='url' value={mydta.url} onChange={handleChnage} />

            <Inputcom label={'Form Url'} placeholder={'Please Enter Form URL'} name='formurl' value={mydta.formurl} onChange={handleChnage} />

            <Inputcom label={'Tracking Time'} placeholder={'Please Enter Tracking Time'} name='tracktime' value={mydta.tracktime} onChange={handleChnage} />

            <Inputcom label={'Confirm Time'} placeholder={'Please Enter Confirm Time'} name='confirmtime' value={mydta.confirmtime} onChange={handleChnage} />


            <Latselct label={'Store Category'} data={options} onChange={handlecategoryselect} name={'category'} value={updateoption} />



            <Inputcom label={'Offer Icon Url'} placeholder={'Please Enter Offer icon URL'} name='icon' value={mydta.icon} onChange={handleChnage} />

            {<div className='flex justify-center  '>
                <img className='bg-red-200 rounded-md' src={mydta.icon ? mydta.icon : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{ width: 300, height: 100, padding: 3 }} />
            </div>}

            <Textareacom label='keypoints Store' placeholder='Please Enter Keypoints this Store' name='keypoints' value={mydta.keypoints} onChange={handleChnage} />

            <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage} />



            <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage} />

            {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}

            {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

            <Selectcom label={'Missing Accepted'} data={['Accepted', 'Not-Accepted']} name='missing' onChange={handleChnage} value={mydta.missing} />

            <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />

            <Selectcom label={'is Popular'} data={['true', 'false']} name='ispopular' onChange={handleChnage} value={mydta.ispopular} />
            
            <Selectcom label={'is Trending'} data={['true', 'false']} name='istrending' onChange={handleChnage} value={mydta.istrending} />


            <Inputcom label={'Keyword'} placeholder={'Please Enter Store Expire Date'} name='keywords' value={mydta.keywords} onChange={handleChnagekeywords} />





            <Inputcom label={'Store Expire Date'} placeholder={'Please Enter Store Expire Date'} name='expiredat' value={mydta.expiredat} onChange={handleChnage} />

            


            <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/stores'} dispatchfun={fethstores}/>

            <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id ? 'UPDATE STORE' : 'ADD STORE'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>








        </div>
    )
}

export default Addstore