import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethOffers } from '../../redux/actions/offeraction'
import SendnotifiAll from '../../Components/SendnotifiAll'
import NotificationTest from '../../Components/NotificationTest'

const Addoffer = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  const [fcmtokens,setfcmtokens] = useState(['eI1K_nXgT3iICWF08nsWvt:APA91bH4kpHS-YI0_JpVoafWsAD6nq0l96bP4wzIolg2HzQJaywRJ_FcACCsH9FwztOW9urMHPJvMGKAz6-a4vsWI0AGY-8lrZhGGFudHdmW40Sx0kXIDR764e7XbMSXwBbLB_QeEHPz','frJpZca7SMi-JXOTzBHjr6:APA91bHTA0wzGHWHeSpNyvBR1-nnsBc1jFQhaeYzti4QeDJv7u2VLROA88I5reLD685sgsE3pzpw0FdTlR8SRz50VhSFpRtAAeXtjySRKcS9I97SKDqKtPmxYfyyEExoopczjRZ69bdS'])

  const state5 = useSelector((state)=>state.selectedReducer)
  const allfcmtokens = useSelector((state)=>state.userReducer).map((val)=>val.fcmtoken)
  

  const token = localStorage.getItem('token')
const [sendnoti,setsendnoti] = useState('')



const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    partnerid:'',
    userid:'',
    txnname:'',
    status:'',
    orderamount:'',
    orderid:'',
    isaccepted:'',
    
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}




const callingAPI = ()=>{
  dispatch(fethselectedoffer(id))
}

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.txnid==id)

  userData.map((val)=>setmydta(val))

 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}

const handlBtnClk = ()=>{
  
  
   

    id?gpeapi.put(`/updatoffer/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/addoffer',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then(async (res)=>{
    setnotidata({msg:res.data.msg,show:true,success:res.data.success})
    if(!id){
      if(mydta.isactive){
        if(sendnoti=='true'){
          const msgss = {
            title:`New Offer Added!`,
            subtitle:`Complete ${mydta.name} to earn ₹${mydta.amount}`,
            img:mydta.icon,
            tokens:allfcmtokens,
            
          }

          await NotificationTest(msgss)

        //   const res = await gpeapi.post('/sendmsgtoall',msgss,{headers:{
        //     "Authorization":`Beaer ${token}`
        // }}).then((res)=>{
        //     alert('Notification Sent')
        //     console.log(res.data)
        // }).catch((e)=>alert('Notification Not Sent some Error '))


         
        }else{
          console.log('notification not sentfg')
        }
      }
    }
    
  }).catch((e)=>console.log(e))


  // console.log(mydta)
  }


// console.log(state5[0])
const match = state5[0]?.txnid==id

useEffect(()=>{
  id && callingAPI()
  updateForm()
},[match,id])





  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={'partnerid'} placeholder={'Please Enter partner id'} name='partnerid' value={mydta.partnerid} onChange={handleChnage}  />
        <Inputcom  label={'Userid'} placeholder={'Please Enter user id'} name='userid' value={mydta.userid} onChange={handleChnage}/>
        <Inputcom label={'txn name'} placeholder={'Please Enter txn name'} name='txnname' value={mydta.txnname} onChange={handleChnage}/>

        <Inputcom label={'status'} placeholder={'Please Enter status'} name='status' value={mydta.status} onChange={handleChnage}/>
        <Inputcom label={'Order amount'} placeholder={'Please Enter order amount'} name='orderamount' value={mydta.orderamount} onChange={handleChnage}/>
        <Inputcom label={'order id'} placeholder={'Please Enter order id'} name='orderid' value={mydta.orderid} onChange={handleChnage}/>
        <Inputcom label={'isaccepted'} placeholder={'Please Enter isaccepted'} name='isaccepted' value={mydta.isaccepted} onChange={handleChnage}/>
        {/* {<div className='flex justify-center  '>
          <img className='bg-red-200 rounded-md' src={mydta.icon?mydta.icon:'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{width:300 , height:100,padding:3}}/>
          </div>}

        <Textareacom label='About Offer' placeholder='Please Enter About this Offer' name='about' value={mydta.about} onChange={handleChnage}/>

        <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage}/>
        <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage}/> */}
        {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}
        
        {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

        {/* <Selectcom label={'is Active'} data={['true','false']} name='isactive' onChange={handleChnage} value={mydta.isactive}/>

        {!id&&<Selectcom label={'Send Notification ?'} data={['true','false']} name='sendnoti' onChange={(e)=>setsendnoti(e.target.value)} value={sendnoti}/>}

        

        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/offers'} dispatchfun={fethOffers}/> */}

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE OFFER':'ADD OFFER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>


       

        


        



    </div>
  )
}

export default Addoffer