import { combineReducers } from "redux";
import userReducer from "./userreducer";
import offerReducer from './offerreducer';
import txnReducer from "./txnreducer";
import witxnReducer from "./witxnreducer";
import selectedReducer from "./selectedreducer";
import bannerReducer from "./bannersreducer";
import basicReducer from "./basicreducer";
import storeReducer from "./storereducer";
import categoryReducer from "./caregoryreducer";
import dealReducer from "./dealreducer";
import clickReducer from "./clickreducer";
import promoReducer from "./promoreducer";
import clonedReducer from "./clonedreducer";






const rootReducer = combineReducers({
    userReducer,offerReducer,txnReducer,witxnReducer,selectedReducer,bannerReducer,basicReducer,storeReducer,categoryReducer,dealReducer,clickReducer,promoReducer,clonedReducer
    
  });
  
  export default rootReducer;