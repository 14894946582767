import React from 'react'
import Datatable from '../../Components/Datatable'
import Newdatatable from '../../Components/Newdatatable'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Btnwithicon from '../../Components/Btnwithicon'
import BannersTable from '../../Components/Tablesdata/BannersTable'
import BasicTable from '../../Components/Tablesdata/BasicTable'


const Basicmain = () => {

  const navigate = useNavigate()

  const AddofferBtnClk = () => {
    navigate('/addbanner')

  }

  const userData = useSelector((state) => state.basicReducer)
  


  
  return (


    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD BANNERS'} icon={faCirclePlus} onClick={AddofferBtnClk} />
      </div>
      
      <BasicTable data={userData}/>
    </div>
  )
}

export default Basicmain