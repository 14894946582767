import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Mybtn from '../../Components/Mybtn'
import Textcompo from '../../Components/Shortcom/Textcompo'
import Clickstable from '../../Components/Tablesdata/Clickstable'
import Refertable from '../../Components/Tablesdata/Refertable'
import Txndatatablecom from '../../Components/Txndatatablecom'

const Viewuser = () => {

    const navigate = useNavigate() 

    const { id } = useParams()

    const userData = useSelector((state) => state.userReducer).filter((val) => val.id == id)[0]
    const bookingData = useSelector((state) => state.offerReducer).filter((val) => val.userid && val.partnerid == id)
    const userclicks = useSelector((state) => state.clickReducer).filter((val) => val.userid == id)
    const usertxn = useSelector((state) => state.txnReducer).filter((val) => val.userid == id)
    const useralldata = useSelector((state) => state.userReducer).filter((val) => val.referby == userData.refercode)



    const paidamt = () => {
        const withdrwadataamt = usertxn.filter((val) => val.type == 'Paid' && val.status == 'Successful')
        if (withdrwadataamt.length != 0) {
            const newamt = withdrwadataamt.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0

    }

    const pendigamt = () => {
        const withdrwadataamt = usertxn.filter((val) => val.type !== 'Paid' && val.status == 'Pending')
        if (withdrwadataamt.length != 0) {
            const newamt = withdrwadataamt.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    
    const rejectamt = () => {
        const withdrwadataamt = usertxn.filter((val) => val.type !== 'Paid' && val.status == 'Rejected')
        if (withdrwadataamt.length != 0) {
            const newamt = withdrwadataamt.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    const pendingwithamt = () => {
        const withdrwadataamt = usertxn.filter((val) => val.type == 'Paid' && val.status == 'Pending')
        if (withdrwadataamt.length != 0) {
            const newamt = withdrwadataamt.map((val) => val.amount).reduce((pre, cur) => pre + cur)
            return newamt
        }
        return 0
    }

    const totalearning = () => {

        if (usertxn.length != 0) {
            const walletto = usertxn.map((val) => val.type !="Paid" && val.amount).reduce((pre, cur) => pre + cur)

            return walletto
        }
        return 0
    }


    const pendingBooking = ()=>{
        if (bookingData.length != 0) {
            const walletto = bookingData.filter((val)=> val.status=='Pending' && val.userid && val.partnerid == id)
            return walletto.length
        }
        return 0
    }

    const successBooking = ()=>{
        if (bookingData.length != 0) {
            const walletto = bookingData.filter((val)=> val.status=='success' && val.userid && val.partnerid == id)
            return walletto.length
        }
        return 0
    }

    const rejectBooking = ()=>{
        if (bookingData.length != 0) {
            const walletto = bookingData.filter((val)=> val.status=='cancel' && val.userid && val.partnerid == id)
            return walletto.length
        }
        return 0
    }




    const [option, setoption] = useState('clicks')


    const listitemtab = [
        { value: 'clicks', label: 'Click Activity' },
        { value: 'txn', label: 'Cashback Activity' },
        { value: 'refer', label: 'Referral Network' },
    ]

    const showfulldatetime = (pdate) => {
        const mydate = new Date(pdate)
        var date = mydate.getDate()
        var month = mydate.getMonth()
        var year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
      
        var strTime = `${date}-${month+1}-${year} ${hours}:${minutes} ${ampm}`

        return strTime

    }

    const addpayment = ()=>{
        navigate('/addtxn',{state:{uid:id}})
    }


    return (
        <div className='maindiv'>

            <div className='profilecard1'>

                <div style={{ display: 'block', justifyContent: 'center', }}>
                    <img className='profileimg' src={userData?.profile_pic?userData?.profile_pic:'https://www.cashpakar.com/web/img/default-avatar.png'} />
                    <p className='paragraphprofile'>{id}</p>
                    <p className='paragraphprofile'>{userData?.name}</p>
                    <p className='paragraphprofile'>{userData?.mobile}</p>
                    <p className='paragraphprofile'>{userData?.email}</p>
                    <p className='paragraphprofile'>userRate: {userData?.user_rate}₹</p>
                    <p className='paragraphprofile'>serviceType: {userData?.service_type}</p>
                    <p className='paragraphprofile'>category: {userData?.main_categories}</p>
                    <p className='paragraphprofile'>status: {userData?.status?'Yes':'No'}</p>
                    <p className='paragraphprofile'>isverified: {userData?.isverified?'Yes':'No'}</p>
                    <p className='paragraphprofile'>is_premium: {userData.is_premium?'Yes':'No'}</p>
                    <p className='paragraphprofile'>gender: {userData?.gender}</p>
                    <p className='paragraphprofile'>role: {userData?.role}</p>
                    <p className='paragraphprofile'>dob: {showfulldatetime(userData?.dob)}</p>
                    <p className='paragraphprofile'>locationArea: {userData?.location_area}</p>
                    <p className='paragraphprofile'>fulladdress: {userData?.address}</p>
                    <p className='paragraphprofile'>city: {userData?.city}</p>
                    <p className='paragraphprofile'>state: {userData?.state}</p>
                    <p className='paragraphprofile'>PinCode: {userData?.pincode}</p>
                    {/* <p className='paragraphprofile'>userRate: {userData?.email}</p> */}

                    {userData?.lastlogin && <p className='paragraphprofile'>Last Login: {userData?.lastlogin == 'active' ? 'active' : showfulldatetime(userData?.lastlogin)}</p>}


                </div>

                <Textcompo text={'Total Bookings'} subtext={bookingData.length} bgcolor={'#00a65a'} />
                <Textcompo text={'Current Balance'} subtext={`₹${userData?.wallet}`} bgcolor={'#3c8dbc'} />
                <Textcompo text={'Success Bookings'} subtext={successBooking()} bgcolor={'#be8f45'} />
                <Textcompo text={'Pending Bookings'} subtext={pendingBooking()} bgcolor={'#be8f45'} />
                <Textcompo text={'Rejected Bookings'} subtext={rejectBooking()} bgcolor={'red'} />

                {/* <Textcompo text={'Withdraw Pending'} subtext={`₹${pendingwithamt()}`} bgcolor={'#e65b32'} />
                <Textcompo text={'Paid Cash'} subtext={`₹${paidamt().toFixed(2)}`} bgcolor={'#00a65a'} /> */}
                <Textcompo text={'Referral Code'} subtext={userData?.refercode} bgcolor={'#b00cf4'} />
                <Textcompo text={'Referral Via'} subtext={userData?.referby} bgcolor={'#740cf4'} />

                {/* <Mybtn title={'Add Payment'} bgcolor={'#3c8dbc'} color={'white'} onClick={addpayment}/> */}




            </div>


            <div className='profilecard2'>

                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        listitemtab.map((val, i) => (
                            <div key={i} className='optionactivity' onClick={() => setoption(val.value)} style={{ cursor: 'pointer', borderTop: val.value == option && '3px solid #3c8dbc' }}>
                                <p style={{ cursor: 'pointer' }}>{val.label}</p>
                            </div>
                        ))
                    }

                </div>

                {
                    option == 'clicks' ? (<Clickstable data={userclicks} />) : option == 'txn' ? (<Txndatatablecom data={usertxn} />) : <Refertable data={useralldata} />
                }




                {/* <Clicksactivitytable/> */}

            </div>



        </div>
    )
}

export default Viewuser