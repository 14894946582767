import gpeapi from "../../api/gpeapi";
import { SET_TXN, SET_WITXN } from "../actiontypes/usertypes";


const token = localStorage.getItem('token')



export const fetchwitxn = ()=>{

    return async function(dispatch){

        // const res = await gpeapi.get('/txnhistory?type=Paid',{headers:{
        //     "Authorization":`Beaer ${token}`
        // }})

        // const fillterdtoNew = res?.data?.mydaa?.sort((a,b)=>{return new Date(b.createdAt) - new Date(a.createdAt)})

        // dispatch(setWitxn(fillterdtoNew));

    }
}








export const setWitxn = (data = null) => {
    if (data) {
        return {
            type: SET_WITXN,
            payload: data,
        };
    }

    return {
        type: SET_WITXN,
        payload: [],
    };
};
