import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Selectcom from '../../Components/Selectcom'
import Textareacom from '../../Components/Textareacom'
import { fethselecteduser } from '../../redux/actions/selectedaction'
import { fethusers } from '../../redux/actions/usersaction'
import { useGeolocated } from "react-geolocated";
import Mybtn from '../../Components/Mybtn'
import axios from 'axios'
import Geocode from "react-geocode";
import { GoogleAddressLookup } from 'react-rainbow-components';
import NotificationTest from '../../Components/NotificationTest'
import Alertyesno from '../../Components/Alertyesno'



Geocode.setApiKey("AIzaSyB7faQv3FK5mymgxKDcnzsDigTAUF7AZ8g");

Geocode.setLanguage("en");


const Adduser = () => {

  const containerStyles = {
    maxWidth: 700,
  };








  const { id } = useParams()

  const dispatch = useDispatch()

  const categorydataall = []
  const servicedayall = []


  const state5 = useSelector((state) => state.selectedReducer)
  const basicData = useSelector((state) => state.basicReducer).map((val) => servicedayall.push(...val.servicetype) && servicedayall.push(...val.servicetypehin))



  const categoryData = useSelector((state) => state.categoryReducer).map((val) => categorydataall.push(val.name) && categorydataall.push(val.hiname))



  const token = localStorage.getItem('token')
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const [updatevia, setupdatevia] = useState([{ time: new Date().toISOString(), via: userInfo?.id }])

  // console.log(userInfo?.id)

  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: false
  })

  const [alertshow,setalertshow] = useState(false)

  const [profiledata, setprofiledata] = useState(null)

  const [sendnoti, setsendnoti] = useState(false)

  const [mytypeaddress, setmytypeaddress] = useState(null)

  const [mydta, setmydta] = useState({
    name: '',
    email: '',
    password: '',
    mobile: '',
    isactive: '',
    role: '',
    wallet: '',
    referby: '',
    refercode: '',
    refermoney: '',
    is_premium: '',
    address: '',
    city: '',
    state: '',
    location_area: '',
    location: {
      type: "Point",
      coordinates: []
    },
    pincode: '',
    gender: '',
    dob: '',
    main_categories: '',
    service_type: '',
    user_rate: '',
    experience: '',
    isverified: '',
    status: '',
    overall_rating: '',
    updateby: ''


  })




  const handleChnage = (e) => {
    const name = e.target.name
    const value = e.target.value

    setmydta({ ...mydta, [name]: value })
  }

  // console.log(mydta?.fcmtoken)


  const callingAPI = () => {
    dispatch(fethselecteduser(id))
  }

  const updateForm = async () => {

    const userData = await state5.filter((val) => val.id == id)


    userData.map((val) => {
      setmydta(val)
    })

    if (userData[0].updateby == undefined) {
      setupdatevia([{ time: new Date().toISOString(), via: userInfo?.id }])
    } else {

      userData.map((val) => setupdatevia(val.updateby))
    }



    // console.log('updatevia', updatevia)





    // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

  }

  const handlbtclk = async () => {
    // setmydta({...mydta,updateby:[...mydta.updateby,{time:new Date().toISOString(),via:userInfo?.id}]})
    // setmydta({...mydta,updateby:{ram:'jhgjhd',clg:'iytr'}})
    setalertshow(true)
    
    await setupdatevia([...updatevia, { time: new Date().toISOString(), via: userInfo?.id }])
    await setmydta({ ...mydta, updateby: updatevia })

    // gpeapi.put(`/updateuser/${id}`, { updateby: updatevia }, {
    //   headers: {
    //     "Authorization": `Beaer ${token}`
    //   }
    // }).then((ressss) => console.log(ressss.data))

  }





  // console.log('testing mydta all', mydta.updateby)





  const handlBtnClk = async () => {
    // await handlbtclk()


    // setmydta({ ...mydta, updateby: updatevia })
    // await setupdatevia([...updatevia,{time:new Date().toISOString(),via:userInfo?.id}])

    //  setupdatevia({time:new Date().toISOString(),via:userInfo?.id})
    //  setmydta({...mydta,updateby:updatevia})
    //  await handlbtclk()





    gpeapi.put(`/updateuser/${id}`, { updateby: updatevia }, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    })


    const formData = new FormData();

    if (profiledata) {

      formData.append(
        "profile",
        profiledata,
        profiledata.name

      );
    }


    id ?

      profiledata ? await gpeapi.post('/uploadimg', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(async (myreee) => {

        if (profiledata) {

          gpeapi.put(`/updateuser/${id}`, { ...mydta, profile_pic: myreee.data.url }, {
            headers: {
              "Authorization": `Beaer ${token}`
            }
          }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => setnotidata({ msg: e?.response?.data?.msg, show: true, success: e.response.data.success }))
        } else {


        }
      }

      ) : gpeapi.put(`/updateuser/${id}`, mydta, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then(async (res) => {
        // console.log(res.data)
        setnotidata({ msg: res.data.msg, show: true, success: res.data.success })

        if (sendnoti) {
          const msgtemplate = {
            title: ' Your Partner account has been Approved',
            subtitle: 'आपका भागीदार खाता स्वीकृत हो गया है अब आप अपने पार्टनर अकाउंट को लोगिन कर सकते हैं',
            tokens: [mydta?.fcmtoken]
          }
          const ress = await NotificationTest(msgtemplate)

        }

      }).catch((e) => setnotidata({ msg: e?.response?.data?.msg, show: true, success: e?.response?.data?.success }))


      : (

        await gpeapi.post('/uploadimg', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(async (myreee) => {

          if (myreee.data.url) {

            gpeapi.post('/signup', { ...mydta, profile_pic: myreee.data.url }, {
              headers: {
                "Authorization": `Beaer ${token}`
              }
            }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => setnotidata({ msg: e?.response?.data?.msg, show: true, success: e.response.data.success }))
          } else {
            alert('please fill all')
          }

        })


      )
    // console.log(mydta)
setalertshow(false)

  }









  // console.log(state5[0])
  const match = state5[0]?.id == id

  useEffect(() => {
    id && callingAPI()
    updateForm()
  }, [match, id])




  const handlegetlocation = async () => {

    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };
    navigator.geolocation.getCurrentPosition(mylocationsucc, mylocationerrr, options)

  }

  const mylocationsucc = async (myre) => {


    Geocode.fromLatLng(myre?.coords?.latitude, myre?.coords?.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;


        const finPincode = response.results[0].address_components.filter((val) => val.types[0] == ["postal_code"])[0]
        const findCity = response.results[0].address_components.filter((val) => val.types[0] == ["locality"])[0]
        const findState = response.results[0].address_components.filter((val) => val.types[0] == ["administrative_area_level_1"])[0]
        const findLocationArea = response.results[0].address_components.filter((val) => val.types[0] == ["political"])[0]

        setmydta({ address: address, pincode: finPincode.long_name, city: findCity.long_name, state: findState.long_name, location_area: findLocationArea.long_name, location: { type: "Point", coordinates: [myre?.coords?.longitude, myre?.coords?.latitude] } })






      },
      (error) => {
        console.error(error);
      }
    )




  }


  const mylocationerrr = (res) => {
    alert('Please enable location to find address')

  }


  const handleprofileChange = (e) => {
    const formData = new FormData();
    formData.append(
      "profile",
      profiledata,
      profiledata.name

    );

    // setmydta({ profile_pic: formData })
  }

  // console.log(profiledata)

  const handlesendnotifun = (e) => {
    setsendnoti(e.target.value)
  }



  return (
    <div style={{ width: '90%', marginTop: 30 }}>




      <Inputcom label={'User Name'} placeholder={'Please Enter User Name'} name='name' value={mydta.name} onChange={handleChnage} />
      <Inputcom label={'User email'} placeholder={'Please Enter User email'} name='email' value={mydta.email} onChange={handleChnage} />
      <Inputcom label={'User password'} placeholder={'Please Enter User password'} name='password' value={mydta.password} onChange={handleChnage} />
      <Inputcom label={'User mobile'} placeholder={'Please Enter User mobile'} name='mobile' value={mydta.mobile} onChange={handleChnage} />
      <Inputcom label={'User referby'} placeholder={'Please Enter User referby'} name='referby' value={mydta.referby} onChange={handleChnage} />
      <Inputcom label={'User refercode'} placeholder={'Please Enter User refercode'} name='refercode' value={mydta.refercode} onChange={handleChnage} />
      <Inputcom label={'User refermoney'} placeholder={'Please Enter refermoney'} name='refermoney' value={mydta.refermoney} onChange={handleChnage} />

      {/* <Inputcom label={'User role'} placeholder={'Please Enter role'} name='role' value={mydta.role} onChange={handleChnage} /> */}

      <Selectcom label={'User role'} data={['user', 'partner', 'admin']} name='role' onChange={handleChnage} value={mydta.role} />
      <Inputcom label={'User wallet'} placeholder={'Please Enter wallet'} name='wallet' value={mydta.wallet} onChange={handleChnage} />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', border: '1px solid green' }}>
        <GoogleAddressLookup
          id="gaddresslookup-1"
          label="Address via type"
          onChange={(e) => {
            setmytypeaddress(e)
            const mydt = {
              coords: {
                latitude: e?.geometry?.viewport?.Wa?.lo,
                longitude: e?.geometry?.viewport?.Ia?.lo
              }

            }
            e?.geometry?.viewport && mylocationsucc(mydt)


            // console.log(e?.geometry?.viewport?.Ia?.lo)
            // console.log(e?.geometry?.viewport?.Wa?.lo)

          }}
          value={mytypeaddress}
          placeholder="Enter location"
          apiKey={'AIzaSyB7faQv3FK5mymgxKDcnzsDigTAUF7AZ8g'}
          style={containerStyles}
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto"
        />
        <p>OR</p>
        <Mybtn title={'CUURENT LOCATION'} onClick={handlegetlocation} />
      </div>

      <Inputcom label={'User address'} placeholder={'Please Enter address'} name='address' value={mydta.address} onChange={handleChnage} />

      <Inputcom label={'User city'} placeholder={'Please Enter city'} name='city' value={mydta.city} onChange={handleChnage} />

      <Inputcom label={'User state'} placeholder={'Please Enter state'} name='state' value={mydta.state} onChange={handleChnage} />

      <Inputcom label={'User location_area'} placeholder={'Please Enter location_area'} name='location_area' value={mydta.location_area} onChange={handleChnage} />

      <Inputcom label={'User pincode'} placeholder={'Please Enter pincode'} name='pincode' value={mydta.pincode} onChange={handleChnage} />






      {/* <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage}/> */}
      {/* <Fileselecotorcom label={'Upload Offer Logo'} /> */}

      {/* <div>
            <p className='text-left mb-2 mt-4'>is Active</p>
        <Radiobtncom label={'Yes'}  name='isactive' value={true} dcheck={mydta.isactive=="true"?true:false}  onChange={handleChnage} />
        <Radiobtncom label={'No'} name='isactive' value={false} dcheck={mydta.isactive=="false"?true:false} onChange={handleChnage}/>
        </div> */}

      <p>Date of Birth
        <input type={'datetime-local'} value={mydta.dob} name='dob' onChange={handleChnage} />
      </p>

      <div>
        <p>Profile Pic</p>
        <input onChange={(e) => {
          setprofiledata(e.target.files[0])
          handleprofileChange()
        }} type={'file'} />
      </div>

      {profiledata &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={URL.createObjectURL(profiledata)} style={{ objectFit: 'contain', height: 100, width: 100, borderRadius: 100, border: '1px solid green' }} />
        </div>
      }

      {mydta.profile_pic &&
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={mydta.profile_pic} style={{ objectFit: 'contain', height: 100, width: 100, borderRadius: 100, border: '1px solid green' }} />
        </div>
      }


      <Selectcom label={'Select Category'} data={categorydataall} name='main_categories' onChange={handleChnage} value={mydta.main_categories} />

      <Selectcom label={'Select Service Type'} data={servicedayall} name='service_type' onChange={handleChnage} value={mydta.service_type} />

      <Inputcom label={'User rate'} placeholder={'Please Enter user rate'} name='user_rate' value={mydta.user_rate} onChange={handleChnage} />



      <Selectcom label={'gender'} data={['Male', 'Female']} name='gender' onChange={handleChnage} value={mydta.gender} />

      <Selectcom label={'Experience'} data={['0', '1', '2', '5', '10', '15', '20']} name='experience' onChange={handleChnage} value={mydta.experience} />

      <Selectcom label={'is status'} data={['true', 'false']} name='status' onChange={handleChnage} value={mydta.status} />

      <Selectcom label={'is verified'} data={['true', 'false']} name='isverified' onChange={handleChnage} value={mydta.isverified} />

      <Selectcom label={'is premium'} data={['true', 'false']} name='is_premium' onChange={handleChnage} value={mydta.is_premium} />

      <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />

      <Selectcom label={'Send Activation Notification'} data={['true', 'false']} value={sendnoti} onChange={handlesendnotifun} />

      <Alertyesno show={alertshow} msg={'Are You Sure You Want To Update?'} yesbtn={handlBtnClk} nobtn={()=>setalertshow(false)}/>

      <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/users'} dispatchfun={fethusers} />

      <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlbtclk} color={'white'} title={id ? 'UPDATE USER' : 'ADD USER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>


      {/* <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlbtclk} color={'white'} title={id ? 'UdfdfPDATE USER' : 'AdsfdfDD USER'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div> */}









    </div>
  )
}

export default Adduser