import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import Dashuper from './Dashuper'
import { useDispatch, useSelector } from 'react-redux'
import { fethusers } from '../../redux/actions/usersaction'
import { fethOffers } from '../../redux/actions/offeraction'
import { fethclicks } from '../../redux/actions/clickaction'
import Loadingmy from '../../Components/Shortcom/Loadingmy'
import Mybtn from '../../Components/Mybtn'
import { fetchtxn } from '../../redux/actions/txnaction'
import { fethstores } from '../../redux/actions/storeaction'
import { fetchwitxn } from '../../redux/actions/witxnaction'
import gpeapi from '../../api/gpeapi'
import Newpagination from '../../Components/Newpagination'
import LineChartsDash from '../Charts/LineChartsDash'

const Main = () => {
    const token = localStorage.getItem('token')

    // const dispatch = useDispatch()

    // useEffect(()=>{
    //     dispatch(fethclicks())

    // },[])

    const [tokenvalid, settokenvalid] = useState(null)

    useEffect(() => {
        checkvalidtoken()
    }, [])


    const checkvalidtoken = () => {
        gpeapi.get('/checktoken', {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => {
            if (res.data.success) {
                settokenvalid(true)
            } else {
                settokenvalid(false)
            }


        }).catch((e) => console.log('error'))
    }


    if (tokenvalid == null) {

    } else if (tokenvalid == false) {
        localStorage.clear()
        window.location.reload()
    }



    const [toggle, settoggle] = useState(false)

    const state = useSelector((state) => state.userReducer)
    const state1 = useSelector((state) => state.offerReducer)
    const state2 = useSelector((state) => state.txnReducer)
    const state3 = useSelector((state) => state.witxnReducer)
    const BannerData = useSelector((state) => state.bannerReducer)
    const clickData = useSelector((state) => state.clickReducer)
    const Stores = useSelector((state) => state.storeReducer)

    const bookingDta = useSelector((state) => state.offerReducer)


    const callanydate = (days) => {
        var mysevendays = new Date();
        mysevendays.setDate(mysevendays.getDate() - days);
        return mysevendays.toISOString().split('T')[0]
    }

    const todayclicks = clickData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
    const yesterdayclicks = clickData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
    const todayuser = state.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
    const yesterdayusers = state.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
    const activeonappusers = state.filter((val) => val.lastlogin == 'active')


    const totalcb = () => {
        const datawith = state2 && state2.filter((val) => val.type !== 'Paid').map((val) => val.amount)
        if (datawith.length > 0) {
            const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)
            return reduccc

        }
        return 0
    }



    const approvedcb = () => {
        const datawith = bookingDta && bookingDta.filter((val) => val.status == "success")
        // if (datawith.length > 0) {
        //     const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)
        //     return reduccc

        // }
        return datawith.length
    }

    const pendingcb = () => {
        const datawith = bookingDta && bookingDta.filter((val) => val.type != "Paid" && val.status == "Pending")
        // if (datawith.length > 0) {
        //     const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)
        //     return reduccc

        // }
        return datawith.length
    }

    const rejectedcb = () => {
        const datawith = bookingDta && bookingDta.filter((val) => val.type != "Paid" && val.status == "cancel")
        // if (datawith.length > 0) {
        //     const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)
        //     return reduccc

        // }
        return datawith.length
    }








    const checkwithdone = () => {
        const datawith = state2 && state2.filter((val) => val.type == "Paid" && val.status == "Successful").map((val) => val.amount)
        if (datawith.length > 0) {
            const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)
            return reduccc

        }
        return 0
    }

    const checkwithpending = () => {
        const datawith = state2 && state2.filter((val) => val.type == "Paid" && val.status == "Pending").map((val) => val.amount)
        if (datawith.length > 0) {
            const reduccc = datawith.reduce((prev, curr) => prev + curr).toFixed(1)

            return reduccc


        }
        return 0
    }

    const totaluserr = () => {
        const userInfo = state.filter((val) => val?.role == 'user')

        return userInfo.length
    }

    const totalpartner = () => {
        const userInfo = state.filter((val) => val?.role == 'partner')
        return userInfo.length
    }


    const pendingpartner = () => {
        const userInfo = state.filter((val) => val?.role == 'partner' && val.isactive == false)
        return userInfo.length

    }
    const dispatch = useDispatch()

    const [loading, setloading] = useState(false)


    const handlerefere = async () => {
        await setloading(true)
        dispatch(fetchtxn())
        await dispatch(fethusers())
        dispatch(fethOffers())
        dispatch(fethclicks())
        dispatch(fethstores())
        dispatch(fetchwitxn())
        await setloading(false)
    }





    return (
        <div className={toggle ? "main active" : "main"}>
            <Header settoggle={settoggle} toggle={toggle} />
            {loading && <Loadingmy />}

            <Mybtn title={'Refresh'} color={'white'} onClick={handlerefere} />

            {/* <div className='container'>
                <LineChartsDash />
            </div> */}



            <div className='cardBox'>
                {state.length < 1 && <Loadingmy />}
                <Dashuper userdata={state} offerdata={state1} bannerData={BannerData} todayclick={todayclicks} yesclicks={yesterdayclicks} stores={Stores} todayuser={todayuser} yesuser={yesterdayusers} activeuser={activeonappusers} totalcb={totalcb()} approvedcb={approvedcb()} pendingcb={pendingcb()} totalbooking={bookingDta.length} rejectcb={rejectedcb()} withdone={checkwithdone()} withdrwapending={checkwithpending()} totalpartner={totalpartner()} totaluser={totaluserr()} pendingpartners={pendingpartner()} />
            </div>

            {/* <Newpagination/> */}





        </div>
    )
}

export default Main