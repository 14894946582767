
import { faCirclePlus, faRemove, faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import { useDispatch, useSelector } from 'react-redux'
import { fethselectedcategory, fethselectedoffer } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethcategory } from '../../redux/actions/categoryaction'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Mybtn from '../../Components/Mybtn'


const Addcategory = () => {

  const { id } = useParams()

  const dispatch = useDispatch()

  const state5 = useSelector((state) => state.selectedReducer)

  const [formValues, setFormValues] = useState([{ subname: "", subhiname: "" }])


  const token = localStorage.getItem('token')

  const [notidata, setnotidata] = useState({
    msg: '',
    success: '',
    show: false
  })

  const [mydta, setmydta] = useState({
    name: '',
    hiname: '',
    icon: '',
    sortdes: '',
    isactive: '',
    ispopular: '',
    fulldes: '',
    subcatename: ''


  })


  


  const handleChnage = (e) => {
    const name = e.target.name
    const value = e.target.value

    setmydta({ ...mydta, [name]: value })
  }




  const callingAPI = () => {
    dispatch(fethselectedcategory(id))
  }

  const updateForm = async () => {

    const userData = await state5.filter((val) => val.id == id)

    userData.map((val) => setmydta(val))

    if (userData[0].subcatename.length == 0) {
      setFormValues([{ subname: "", subhiname: "" }])
    } else {

      userData.map((val) => setFormValues(val.subcatename))
    }

    

    // console.log(userData)


    // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})

  }


  const handlBtnClk = () => {


    id ? gpeapi.put(`/updatecategory/${id}`, mydta, {
      headers: {
        "Authorization": `Beaer ${token}`
      }
    }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))
      : gpeapi.post('/addcategory', mydta, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))

    // console.log(mydta)
  }









  // console.log(state5[0])
  const match = state5[0]?.id == id

  useEffect(() => {
    id && callingAPI()
    updateForm()
  }, [match, id])


  const handleChnagenew = (e, i) => {
    const value = e.target.value;
    const name = e.target.name;
    const list = [...formValues]
    list[i][name] = value
    setFormValues(list)



  }


  const handleplushicon = () => {
    setFormValues([...formValues, { subname: "", subhiname: "" }])
  }

  const handleremoveicon = (i) => {
    const list = [...formValues];
    list.splice(i, 1)
    setFormValues(list)


  }

  const cashratedataadd = () => {
    setmydta({ ...mydta, subcatename: formValues })
    // console.log(mydta)
    alert('added done')
  }






  return (
    <div style={{ width: '90%', marginTop: 30 }}>

      <Inputcom label={'Category Name'} placeholder={'Please Enter Category Name'} name='name' value={mydta.name} onChange={handleChnage} />
      <Inputcom label={'Category Name Hindi'} placeholder={'Please Enter Category Name in Hindi'} name='hiname' value={mydta.hiname} onChange={handleChnage} />

      <Inputcom label={'Category Icon Url'} placeholder={'Please Enter Category icon URL'} name='icon' value={mydta.icon} onChange={handleChnage} />
      {<div className='flex justify-center  '>
        <img className='bg-red-200 rounded-md' src={mydta.icon ? mydta.icon : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{ width: 300, height: 100, padding: 3 }} />
      </div>}



      <Inputcom label={'Category Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage} />



      <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />

      <Selectcom label={'is Popular'} data={['true', 'false']} name='ispopular' onChange={handleChnage} value={mydta.ispopular} />

      <Textareacom label='Full Description' placeholder='Please Enter Full Description' name='fulldes' value={mydta.fulldes} onChange={handleChnage} />


      <div style={{ marginTop: 15 }}>
        <label htmlFor="countries" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-400">Add Subcategory</label>
      </div>



      <div style={{ border: '2px solid #e65b32', borderRadius: 8, marginTop: 5 }}>



        {formValues && formValues.map((val, i) => (
          <div key={i} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}><Inputcom label={'Subcategory English'} placeholder={'Please Enter Subcategory English Name'} name='subname' value={val.subname} onChange={(e) => handleChnagenew(e, i)} />

            <Inputcom label={'Subcategory Hindi'} placeholder={'Please Enter Subcategory Hindi Name'} name='subhiname' value={val.subhiname} onChange={(e) => handleChnagenew(e, i)} />

            {
              formValues.length !== 1 && <FontAwesomeIcon onClick={() => handleremoveicon(i)} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faRemove} />
            }
            {
              formValues.length - 1 == i && <FontAwesomeIcon onClick={handleplushicon} style={{ marginLeft: 6, cursor: 'pointer', fontSize: 30 }} icon={faCirclePlus} />
            }

          </div>
        ))}
      </div>

      <Mybtn title={'Add'} color={'white'} onClick={cashratedataadd} />




      <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/categorys'} dispatchfun={fethcategory} />



      <div style={{ margin: '60px 0px' }}>
        <p style={{ fontSize: 11, color: 'red', marginBottom: 5 }}>***Please Before Click on Add Category Button Click on Add Button in Subcategory </p>
        <Btnwithicon onClick={handlBtnClk} color={'white'} title={id ? 'UPDATE CATEGORY' : 'ADD CATEGORY'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>








    </div>
  )
}

export default Addcategory