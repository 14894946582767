import axios from 'axios'
import gpeapi from "../../api/gpeapi";
import {SET_BASIC } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethbasic = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getbasicdet',{headers:{
            "Authorization":`Beaer ${token}`
        }})

      

        dispatch(setBasic([res.data.signupamt]));
       
    }
}






export const setBasic = (data = null) => {
    if (data) {
        return {
            type: SET_BASIC,
            payload: data,
        };
    }

    return {
        type: SET_BASIC,
        payload: [],
    };
};


