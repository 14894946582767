



import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faIndianRupeeSign,faXmark,faEye,faEdit,faTrash,faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Deletenoti from '../Deletenoti';
import gpeapi from '../../api/gpeapi';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from '../Pagination';
import Loadingmy from '../Shortcom/Loadingmy';


const Pmclaimedtable = ({options,data,code}) => {
  const token = localStorage.getItem('token')

  const txnData = useSelector((state)=>state.txnReducer)


  const navigate = useNavigate()


  const genratedate =(datee)=>{
    const mydate = new Date(datee)
const todate = mydate.getDate()
const month = mydate.getMonth()
const year = mydate.getFullYear()
var hours = mydate.getHours();
var minutes = mydate.getMinutes();
var ampm = hours >= 12 ? 'pm' : 'am';
hours = hours % 12;
hours = hours ? hours : 12; // the hour '0' should be '12'
minutes = minutes < 10 ? '0'+minutes : minutes;

return `${todate}-${month}-${year} ${hours}:${minutes} ${ampm}`
}




const getdatedataa = (id,code)=>{
    const filterdata = txnData.filter((val)=>val.userid==id && val.txnname==code)[0]
    const datee = genratedate(filterdata?.createdAt)

   
    return datee
    
   
}







const [currentPage, setCureentPage] = useState(1)
const [userPerPage, setuserPerPage] = useState(20)
const [myfilter, setmyfilter] = useState([])


const indexLastUser = currentPage * userPerPage;
const indexFirstUser = indexLastUser - userPerPage;
const currentuser = data?.slice(indexFirstUser, indexLastUser)


useEffect(() => {
  setmyfilter(data)
}, [data])

const paginate = (number) => {
  setCureentPage(number)
}

    
  return (
    <div className='container' style={{margin:'20px 5px'}}>
      <br />

     

      <div className='d-flex justify-content-between'>
        


        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table  sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead>
            <TableRow>
           
              <TableCell ><strong>ID</strong></TableCell>
              <TableCell ><strong>UserID</strong></TableCell>
              <TableCell><strong>Claimed Date </strong></TableCell>
              {/* <TableCell><strong>Email</strong></TableCell>
              <TableCell><strong>Wallet</strong></TableCell> */}
              
              
            </TableRow>
          </TableHead>
          <TableBody>
            { currentuser && currentuser.map((row,i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {i+1}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>

     

                
              
                <TableCell >{getdatedataa(row,code)}</TableCell>
                {/* <TableCell >{row.wallet}₹</TableCell> */}
                {/* <TableCell >{getdatevia(row.createdAt)}</TableCell>
                <TableCell style={{fontSize:10}}>{row.clickid}</TableCell> */}
               
                
                {/* <TableCell  style={{display:'flex',justifyContent:'space-evenly',border:0}}>
                    <FontAwesomeIcon className='usertablicon'  color='yellowgreen' icon={faEye}  />
                    <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={()=>handleditclk(row.id)} icon={faEdit} />
                    <FontAwesomeIcon className='usertablicon' color='red' onClick={()=>handledeletclk(row.id)} icon={faTrash} />
                   
                    </TableCell> */}
                
                



              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination perPage={userPerPage} totalData={data?.length} paginate={paginate} currentPage={currentPage}/>

    </div>
  )
}

export default Pmclaimedtable