
import gpeapi from "../../api/gpeapi";
import NewtestPop from "../../Components/NewtestPop";
import Popupnoti from "../../Components/Popupnoti";
import { Myfun } from "../../Components/Testfun";
import { ADD_TXN, SET_PERUSER, SET_TXN, SET_WITXN } from "../actiontypes/usertypes";





const token = localStorage.getItem('token')

export const fetchtxn = ()=>{

    return async function(dispatch){

        const res = await gpeapi.get('/txnhistory',{headers:{
            "Authorization":`Beaer ${token}`
        }})

        const fillterdtoNew = res.data.txndata.sort((a,b)=>{return new Date(b.createdAt) - new Date(a.createdAt)})


        dispatch(setTxn(fillterdtoNew));

    }
}



export const fetchaddtxn = (data)=>{

    return async function(dispatch){
        

        const res = await gpeapi.post('/addtxnhistory',data,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        // console.log(res.data)
        alert(res.data.msg)
        // Popupnoti({show:true,msg:res.data.msg,success:res.data.success})
        // Myfun(res.data)
        // NewtestPop(res.data)
        
        dispatch(addTxn(res.data.suc));

    }
   
}

export const fetchtxnuser = (id)=>{

    return async function(dispatch){

        const res = await gpeapi.get(`/txnhistory/${id}`,{headers:{
            "Authorization":`Beaer ${token}`
        }})

        dispatch(setTxnUser(res.data.mydta));

    }
}






export const setTxn = (data = null) => {
    if (data) {
        return {
            type: SET_TXN,
            payload: data,
        };
    }

    return {
        type: SET_TXN,
        payload: [],
    };
};

export const addTxn = (data = null) => {
    if (data) {
        return {
            type: ADD_TXN,
            payload: data,
        };
    }

    return {
        type: ADD_TXN,
        payload: [],
    };
};


export const setTxnUser = (data = null) => {
    if (data) {
        return {
            type: SET_PERUSER,
            payload: data,
        };
    }

    return {
        type: SET_PERUSER,
        payload: [],
    };
};





