import React from 'react'
import { useNavigate } from 'react-router-dom'
import Mybtn from '../../Components/Mybtn'

const Mainnotification = () => {

    const navigate = useNavigate()
    const AddofferBtnClk = ()=>{
        navigate('/addnotification')
       
    }
  return (
    <div>
        <Mybtn title={'SEND NOTIFICATION'} onClick={AddofferBtnClk}/>
    </div>
  )
}

export default Mainnotification