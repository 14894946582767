import React from 'react'

const DashCard = ({number,title,icon}) => {
    return (
        <div class="card" style={{flexDirection:'row',alignItems:'center'}}>
            <div>
                <div class="numbers">{number}</div>
                <div class="cardName">{title}</div>
            </div>

            <div class="iconBx">
                <ion-icon name={icon}></ion-icon>
            </div>
        </div>
    )
}

export default DashCard