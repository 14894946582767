
import './App.css';
import './globle.css';
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes, Route
} from "react-router-dom";


import Navigationslid from './Components/Navigationslid';
import Main from './pages/Dashboard/Main';
import Header from './Components/Header';
import Usermain from './pages/Users/Usermain';
import {useDispatch,useSelector} from 'react-redux'
import { fethusers } from './redux/actions/usersaction';
import { fethOffers } from './redux/actions/offeraction';
import Mainoffer from './pages/Offers/Mainoffer';
import Addoffer from './pages/Offers/Addoffer';
import Maintxn from './pages/Txnhistory/Maintxn';
import { fetchtxn } from './redux/actions/txnaction';
import { fetchwitxn } from './redux/actions/witxnaction';

import Mainwithdrwa from './pages/Txnhistory/Mainwithdrwa';
import Addtxn from './pages/Txnhistory/Addtxn';
import Adduser from './pages/Users/Adduser';
import Bannermain from './pages/Banners/Bannermain';
import {fethbanners} from './redux/actions/banneraction'
import Addbanner from './pages/Banners/Addbanner';
import Basicmain from './pages/BasicDetails/Basicmain';
import { fethbasic } from './redux/actions/basicaction';
import Addbasic from './pages/BasicDetails/Addbasic';
import Mainlogin from './pages/Login/Mainlogin';
import { useState } from 'react';
import gpeapi from './api/gpeapi';
import Addstore from './pages/Store/Addstore';
import Mainstore from './pages/Store/Mainstore';
import { fethstores } from './redux/actions/storeaction';
import { fethcategory } from './redux/actions/categoryaction';
import Maincategory from './pages/Category/Maincategory';
import Addcategory from './pages/Category/Addcategory';
import { fethdeals } from './redux/actions/dealaction';
import Maindeal from './pages/Deals/Maindeal';
import Adddeal from './pages/Deals/Adddeal';
import Mainclicks from './pages/Clicks/Mainclicks';
import { fethclicks } from './redux/actions/clickaction';
import Viewuser from './pages/Users/Viewuser';
import Pendingtxn from './pages/Txnhistory/Pendingtxn';
import Mainnotification from './pages/Notification/Mainnotification';
import Addnotification from './pages/Notification/Addnotification';
import Mainpmcode from './pages/Pmcode/Mainpmcode';
import Addpmcode from './pages/Pmcode/Addpmcode';
import { fethPromocode } from './redux/actions/promoaction';
import Viewpmcode from './pages/Pmcode/Viewpmcode';
import Mainclone from './pages/Cloned/Mainclone';
import Addclone from './pages/Cloned/Addclone';
import Googlemap from './pages/MapGoogle/Googlemap';
import Viewbooking from './pages/Offers/Viewbooking';
import Partnermain from './pages/Users/Partnermain';
import Mainanalat from './pages/Analatics/Mainanalat';


function App() {

  const token = localStorage.getItem('token')
  const [tokenvalid,settokenvalid] = useState(null)

 

  const dispatch = useDispatch()


  useEffect(()=>{
    checkvalidtoken()
  },[])


  const checkvalidtoken = ()=>{
    gpeapi.get('/checktoken',{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>{
    if(res.data.success){
      settokenvalid(true)
    }else{
      settokenvalid(false)
    }


  }).catch((e)=>console.log('error'))
  }


  useEffect(()=>{
    dispatch(fethusers())
    dispatch(fethOffers())
    dispatch(fetchtxn())
    dispatch(fetchwitxn())
    dispatch(fethbanners())
    dispatch(fethbasic())
    dispatch(fethstores())
    dispatch(fethcategory())
    dispatch(fethdeals())
    dispatch(fethclicks())
    dispatch(fethPromocode())
   
    


    
},[])

  return (

    <Router>


      {
        token?(
          <div className="App">
          <Navigationslid />
  
  
          <div>
            
            <Routes>
            
              <Route path='/' element={<Main />} />
              <Route path='/dashboard' element={<Main />} />
              <Route path='/users' element={<Usermain />} />
              <Route path='/partners' element={<Partnermain />} />
              <Route path='/offers' element={<Mainoffer />} />
              <Route path='/stores' element={<Mainstore />} />
              <Route path='/deals' element={<Maindeal />} />
              <Route path='/categorys' element={<Maincategory />} />
              <Route path='/banners' element={<Bannermain />} />
              <Route path='/txnhistory' element={<Maintxn />} />
              <Route path='/basic' element={<Basicmain />} />
              <Route path='/clicks' element={<Mainclicks />} />
              <Route path='/pendingtxnhistory' element={<Pendingtxn />} />
              <Route path='/notification' element={<Mainnotification />} />
              <Route path='/pmcode' element={<Mainpmcode />} />
              <Route path='/cloned' element={<Mainclone />} />
              <Route path='/googlemap' element={<Googlemap />} />
              <Route path='/stats' element={<Mainanalat />} />


              
  
  
              <Route path='/addnotification' element={<Addnotification />} />
              <Route path='/addpmcode' element={<Addpmcode />} />
              <Route path='/addcloned' element={<Addclone />} />
              <Route path='/addoffer' element={<Addoffer />} />
              <Route path='/addstore' element={<Addstore />} />
              <Route path='/adddeal' element={<Adddeal />} />
              <Route path='/addcategory' element={<Addcategory />} />
              <Route path='/addtxn' element={<Addtxn />} />
              <Route path='/adduser' element={<Adduser />} />
              <Route path='/addbanner' element={<Addbanner />} />
              <Route path='/updatetxn/:id' element={<Addtxn />} />
              <Route path='/updateoffer/:id' element={<Addoffer />} />
              <Route path='/updatestore/:id' element={<Addstore />} />
              <Route path='/updatedeal/:id' element={<Adddeal />} />
              <Route path='/updatecategory/:id' element={<Addcategory />} />
              <Route path='/updateuser/:id' element={<Adduser />} />
              <Route path='/viewuser/:id' element={<Viewuser />} />
              <Route path='/viewbooking/:id' element={<Viewbooking />} />
              <Route path='/viewpmcode/:id' element={<Viewpmcode />} />

              <Route path='/updatebanner/:id' element={<Addbanner />} />
              <Route path='/updatebasic' element={<Addbasic />} />
              <Route path='/updatepmcode/:id' element={<Addpmcode />} />
  
  
              <Route path='/withhistory' element={<Mainwithdrwa />} />
              
  
            </Routes>
          </div>
  
  
        </div>
        ):(
          <Routes>
            
          <Route path='/login' element={<Mainlogin/>}/>
          <Route path='/' element={<Mainlogin/>}/>
          <Route path='*' element={<Mainlogin/>}/>
        </Routes>
        )
      }

     



     




    </Router>
  );
}

export default App;
