
import React, { useState } from 'react'
import OfferdataTable from '../../Components/OfferdataTable'
import {useSelector} from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Btnwithicon from '../../Components/Btnwithicon';
import {useNavigate} from "react-router-dom";
import Storetable from '../../Components/Tablesdata/Storetable';
import Dealtable from '../../Components/Tablesdata/Dealtable';

const Maindeal = () => {

    const navigate = useNavigate()

    const storeData = useSelector((state)=>state.dealReducer)
    const mystoreData = useSelector((state)=>state.storeReducer)

    // console.log(storeData)

    const AddofferBtnClk = ()=>{
        navigate('/adddeal')
       
    }

    // const showstorename =async (name)=>{
    //   const myss =await  mystoreData.filter((val)=>val.name.toLowerCase()==name.toLowerCase())[0]
    //   return myss?.id

      
    // }

   

    const [findid, setfindid] = useState('')
  
    const filldata = storeData.filter((x) => {
      return findid !== "" ? x.id.toString().includes(findid) || x.cashbackrate.toString().includes(findid) || x.name.toLowerCase().includes(findid) || x.name.includes(findid) || x.storeid.toString().includes(findid) : x
    })

    // || x.storeid.toString().includes(showstorename(findid))

    
  return (
    <div>
        <div style={{display:'flex',justifyContent:'right',margin:'10px 10px'}}>
        
        <Btnwithicon color={'#2a2185'} title={'ADD DEAL/COUPON'} icon={faCirclePlus} onClick={AddofferBtnClk}/>
        </div>
        <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID" />
        </label>

      </div>
        <Dealtable data={filldata}/>
    </div>
  )
}

export default Maindeal