import axios from 'axios'
import gpeapi from "../../api/gpeapi";
import { SET_BANNERS } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethbanners = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/getbanner',{headers:{
            "Authorization":`Beaer ${token}`
        }})

      

        dispatch(setBanners(res.data.mydta));
       
    }
}






export const setBanners = (data = null) => {
    if (data) {
        return {
            type: SET_BANNERS,
            payload: data,
        };
    }

    return {
        type: SET_BANNERS,
        payload: [],
    };
};
