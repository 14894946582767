import axios from "axios"


const NotificationTest =async (msg)=>{

    

    const array = msg.tokens;
    const new_array = [];
    
    const config = {
        headers: {
            "Authorization": `key=AAAAEBSW53E:APA91bH7Oucw47dtNcqeaI4Thqw96vI6wQDCxGl92DWL2JqWakL456fC-ZyrpYos-OCxcIeZ577N5-Bg4tlYev2-CLUseXPf9_SmU7Byo6FoqhmuybsVrrT3Lt8WCITtni4HJXejtYf0`,
            "Content-type": "Application/json",
        },
    }

   
  

    const chunksize = 490;
    while (array.length) {
        const chunk = array.splice(0, chunksize);
        new_array.push(chunk);
    }
    for (let i = 0; i < new_array.length; i++) {

        const data = {
            "registration_ids":new_array[i],
            "notification": {
                "title": msg.title,
                "body": msg.subtitle,
                "image": msg.img,
                "sound": "ring_bell",
                "icon": "ic_notification",
                "android_channel_id": "sound_channel"
            }
        }

       axios.post('https://fcm.googleapis.com/fcm/send', data, config).then((res) => {
        console.log('Notification Sent Done',res)
        // alert(`Notification Sent to ${res?.data?.success}`)
        return res
    })

    }


   

}

export default NotificationTest