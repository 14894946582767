import axios from 'axios'
import gpeapi from "../../api/gpeapi";
import { SET_OFFERS, SET_USERS } from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethusers = ()=>{
    return async function(dispatch){

        const res = await gpeapi.get('/users',{headers:{
            "Authorization":`Beaer ${token}`
        }})


        const fillterdtoNew = res.data?.TotalUsers?.sort((a,b)=>b.id-a.id)

      

        dispatch(setUsers(fillterdtoNew));
       
    }
}






export const setUsers = (data = null) => {
    if (data) {
        return {
            type: SET_USERS,
            payload: data,
        };
    }

    return {
        type: SET_USERS,
        payload: [],
    };
};
