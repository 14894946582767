import { SET_OFFERS, UPDATE_OFFERS } from "../actiontypes/usertypes";

export const productsInitialState = [];

const offerReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_OFFERS:
        return [...action.payload];

        // case UPDATE_OFFERS:
        //     return [action.payload]
      default:
        return state;
    }
  };
  
  export default offerReducer;