import { SET_PROMO } from "../actiontypes/usertypes";

export const productsInitialState = [];

const promoReducer = (state = productsInitialState, action) => {
    switch (action.type) {
      case SET_PROMO:
        return [...action.payload];

        // case UPDATE_OFFERS:
        //     return [action.payload]
      default:
        return state;
    }
  };
  
  export default promoReducer;