import { faRemove } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



const Deletenoti = ({text,onClick}) => {

//   const MySwal = withReactContent(Swal)



  

    
  return (
    <>
    {

        
    Swal.fire({
        title: 'Are you Sure ',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#2a2185',
        confirmButtonText: `Yes Delete`,
        focusCancel:true,
        
      }).then(async(result) => {
        if (result.isConfirmed) {
          await  onClick()
         
         await Swal.fire(
            `Deleted! `,
            'Your Action has been Successful.',
            'success'
            
          )
          window.location.reload()
         
          

        }
      })
    }
    </>
  )
}

export default Deletenoti