import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIndianRupeeSign, faXmark, faEye, faEdit, faTrash, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import gpeapi from '../api/gpeapi';
import Deletenoti from './Deletenoti';
import { useSelector } from 'react-redux';
import Pagination from './Pagination';

const Datatable = ({ options, data }) => {
  const token = localStorage.getItem('token')

  const txndata = useSelector((state) => state.txnReducer)
  const useralldata = useSelector((state) => state.userReducer)


  const showpendning = (id) => {
    const withdrwadataamt = txndata.filter((val) => val.userid == id && val.type !== 'Paid' && val.status == 'Pending')
    if (withdrwadataamt.length != 0) {
      const newamt = withdrwadataamt.map((val) => val.amount).reduce((pre, cur) => pre + cur)
      return newamt
    }
    return 0
  }

  const navigate = useNavigate()

  const handleClk = (id) => {
    navigate(`/updateuser/${id}`)
  }

  const handleviewclick = (id) => {
    navigate(`/viewuser/${id}`)
  }


  const handledeletclk = (id) => {
    const onMyclk = () => {
      gpeapi.delete(`/deleteuser/${id}`, {
        headers: {
          "Authorization": `Beaer ${token}`
        }
      }
      ).then((res) => res.data).catch((e) => console.log(e))
    }
    Deletenoti({ text: `${id} UserID will Delete in your System`, onClick: onMyclk })
  }



  const [currentPage, setCureentPage] = useState(1)
  const [userPerPage, setuserPerPage] = useState(30)
  const [myfilter, setmyfilter] = useState([])


  const indexLastUser = currentPage * userPerPage;
  const indexFirstUser = indexLastUser - userPerPage;
  const currentuser = data.slice(indexFirstUser, indexLastUser)


  useEffect(() => {
    setmyfilter(data)
  }, [data])

  const paginate = (number) => {
    setCureentPage(number)
  }

  const checkfilternumber = (uid,) => {
    const fildata = useralldata?.filter((val) => val?.deviceuid && val?.deviceuid == uid)


    return fildata.length
  }

 




  return (
    <div className='container'>
      <br />

      <div className='d-flex justify-content-between'>



        {/* <Link to='/student/des'><Button variant="contained">Add New Student</Button></Link> */}




      </div>
      <br />



      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>ID</strong></TableCell>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell ><strong>Email</strong></TableCell>
              <TableCell><strong>Mobile</strong></TableCell>
              <TableCell ><strong>RefBy</strong></TableCell>
              <TableCell ><strong>RefCode</strong></TableCell>
              {/* <TableCell ><strong>DUID</strong></TableCell> */}
              <TableCell ><strong>isActive</strong></TableCell>
              <TableCell ><strong>is Premium</strong></TableCell>
              <TableCell ><strong> Curr Wallet</strong></TableCell>
              <TableCell align='right'><strong>Change</strong></TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {currentuser && currentuser.map((row, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell ><p style={{  display: 'flex' }}>{row.name} <span style={{ backgroundColor: '#e23744', padding: 2, color: 'white', fontSize: 11,height:19, borderRadius: 3,marginLeft:8 }}>    {row.role.slice(0,1)}</span></p></TableCell>
                <TableCell >{row.email.slice(0, 23)}</TableCell>
                <TableCell >{row.mobile}</TableCell>
                <TableCell >{row.referby ? row.referby : 'null'}</TableCell>
                <TableCell >{row.refercode}</TableCell>
                {/* <TableCell ><p style={{ fontSize: 11, display: 'flex' }}>{row.deviceuid} <span style={{ backgroundColor: '#484e54', padding: 2, color: 'white', fontSize: 11, borderRadius: 3 }}>    {checkfilternumber(row.deviceuid)}</span></p></TableCell> */}

                <TableCell >{row.isactive?<FontAwesomeIcon icon={faCircleCheck} color={'#2a2185'} />:<FontAwesomeIcon icon={faXmark} color={'red'}/>}</TableCell>

                <TableCell >{row.is_premium?'Yes':'No'}</TableCell>
                <TableCell style={{ fontWeight: 'bold', color: '#2a2185' }}>{row.wallet}<FontAwesomeIcon icon={faIndianRupeeSign} fontSize={11} /></TableCell>

                <TableCell style={{ display: 'flex', justifyContent: 'space-evenly', border: 0 }}>
                  <FontAwesomeIcon className='usertablicon' color='yellowgreen' onClick={() => handleviewclick(row.id)} icon={faEye} />
                  <FontAwesomeIcon className='usertablicon' color='#2a2185' onClick={() => handleClk(row.id)} icon={faEdit} />
                  <FontAwesomeIcon className='usertablicon' color='red' onClick={() => handledeletclk(row.id)} icon={faTrash} />

                </TableCell>





              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination perPage={userPerPage} totalData={data.length} paginate={paginate} currentPage={currentPage} />

    </div>
  )
}

export default Datatable