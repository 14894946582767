




import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import {useDispatch,useSelector} from 'react-redux'
import { fethselectedoffer, fethselectedpromo } from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { fethOffers } from '../../redux/actions/offeraction'
import SendnotifiAll from '../../Components/SendnotifiAll'
import NotificationTest from '../../Components/NotificationTest'
import { fethPromocode } from '../../redux/actions/promoaction'

const Addclone = () => {

  const {id} = useParams()

  const dispatch = useDispatch()

  
  const state5 = useSelector((state)=>state.selectedReducer)
 
  

  const token = localStorage.getItem('token')
const [sendnoti,setsendnoti] = useState('')



const [notidata, setnotidata] = useState({
  msg:'',
  success:'',
  show:false
})

  const [mydta,setmydta]= useState({
    appname:'',
    apppackage:'',
    
   
})

const handleChnage = (e)=>{
  const name = e.target.name
  const value = e.target.value

  setmydta({...mydta,[name]:value})
}




// const callingAPI = ()=>{
//   dispatch(fethselectedpromo(id))
// }

const updateForm = async ()=>{
        
  const userData = await state5.filter((val)=>val.id==id)
  
  userData.map((val)=>setmydta(val))

 
  // setmydta({type:'Added',status:'Rejected',userid:'2',amount:'10',txnname:'cgcg'})
  
}

// console.log(allfcmtokens)






const handlBtnClk = ()=>{
  
  
   

    id?gpeapi.put(`/updatepmcode/${id}`,mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then((res)=>setnotidata({msg:res.data.msg,show:true,success:res.data.success})).catch((e)=>console.log(e))
    :gpeapi.post('/createclonedapp',mydta,{headers:{
      "Authorization":`Beaer ${token}`
  }}).then(async (res)=>{
    setnotidata({msg:res.data.msg,show:true,success:res.data.success})
    
  }).catch((e)=>console.log(e))


  // console.log(mydta)
  }









// console.log(state5[0])
const match = state5[0]?.id==id

useEffect(()=>{
//   id && callingAPI()
  updateForm()
},[match,id])





  return (
    <div style={{width:'90%',marginTop:30}}>

        <Inputcom label={' Name'} placeholder={'Please EnterName'} name='appname' value={mydta.appname} onChange={handleChnage}  />
        <Inputcom  label={'package name'} placeholder={'Please Enter package'} name='apppackage' value={mydta.apppackage} onChange={handleChnage}/>
    

        
      
        <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/pmcode'} dispatchfun={fethPromocode}/>

        <div style={{margin:'60px 0px'}}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id?'UPDATE OFFER':'ADD PROMOCODE'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'}/></div>


       

        


        



    </div>
  )
}

export default Addclone