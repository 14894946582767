import React from 'react'
import DatePicker from "react-datepicker"
import 'react-datepicker/dist/react-datepicker-cssmodules.css';



const Calendarmy = ({clear,onChange,value}) => {
  return (
    

    <DatePicker
    isClearable
    onChange={onChange}
    selected={value} 
    // locale={state.locale.name}
/>

    
  )
}

export default Calendarmy