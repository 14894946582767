import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-rainbow-components';


const Alertyesno = ({ title, msg, show, yesbtn ,nobtn}) => {
    

    const [open, setopen] = useState(false)

    const handlebtnclk = () => {
        yesbtn()
        setopen(false)

       
    }

    const handlenoclik = ()=>{
        nobtn()
        setopen(false)
    }



    const firstinmi = () => {
        setopen(show)

    }

    useEffect(() => {
        firstinmi()
    }, [show])
    return (
        <div>

            <Modal id="modal-1" isOpen={open} hideCloseButton={true} footer={

                <div className="flex justify-center">
                    <Button style={{ margin: '0px 30px', backgroundColor: '#343c49', border: 0, padding: '5px 20px', fontWeight: 'bold' }} onClick={handlebtnclk} label={'YES'} variant="brand" />
                    <Button  style={{ margin: '0px 30px', backgroundColor: '#637085', border: 0, padding: '5px 20px', fontWeight: 'bold' }} onClick={handlenoclik} label={'NO'} variant="brand" />

                </div>
            }>
                <div className='flex flex-col items-center'>
                    <img
                        src={"https://cdn.dribbble.com/users/251873/screenshots/9288094/media/a1c2f89065f68e1b2b5dcb66bdb9beb1.gif"}
                        className="rainbow-p-around_xx-large rainbow-m_auto rainbow-align-content_center"
                        alt="landscape with rainbows, birds and colorful balloons"
                        style={{ width: 150, height: 110 }}
                    />
                    <p>{msg}</p>
                </div>



            </Modal>
        </div>
    )
}

export default Alertyesno