import React from 'react'
import Select from 'react-select'


const Latselct = ({label,value,onChange,data,name}) => {
  return (
    <div class="mb-6">
    <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label}</label>
    <Select
                isMulti
                name={name}
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={onChange}
                value={value}
            />
</div>
  )
}

export default Latselct