import React from 'react'
import LineChartsDash from '../Charts/LineChartsDash'

const Mainanalat = () => {
  return (
    <div>
         <div className='container'>
                <LineChartsDash />
            </div>

    </div>
  )
}

export default Mainanalat