import React from 'react'
import Loginform from './Loginform'

const Mainlogin = () => {
  return (
    <div>

        <Loginform/>
    </div>
  )
}

export default Mainlogin