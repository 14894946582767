import React from 'react'

const Textcompo = ({text,subtext,bgcolor}) => {
  return (
    <div className='textcompo'>
        <h5>{text}</h5>
        <p style={{backgroundColor:bgcolor,fontSize:12,padding:5,color:'white',fontWeight:'bold',borderRadius:5}}>{subtext}</p>
    </div>
  )
}

export default Textcompo