import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Button } from 'react-rainbow-components'

const Btnwithicon = ({title,icon,onClick,width,bgcolor,color}) => {
    return (
        <Button onClick={onClick} style={{ color: color, borderRadius: 8,fontWeight:'bold',width:width,backgroundColor:bgcolor }}  variant="neutral" className="rainbow-m-around_medium">
            {title}
            <FontAwesomeIcon style={{marginLeft:6}} icon={icon} className="rainbow-m-left_medium" />
        </Button>
    )
}

export default Btnwithicon