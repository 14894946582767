
import gpeapi from "../../api/gpeapi";
import { SET_STORES,} from "../actiontypes/usertypes";

const token = localStorage.getItem('token')
export const fethstores = ()=>{
    return async function(dispatch){

        // const res = await gpeapi.get('/getstore',{headers:{
        //     "Authorization":`Beaer ${token}`
        // }})




        // const fillterdtoNew = res.data?.offerlist?.sort((a,b)=>b.id-a.id)
    


      
        // dispatch(setStores(fillterdtoNew));
        // dispatch(updateOffer(res1.data));
       
    }
}






export const setStores = (data = null) => {
    if (data) {
        return {
            type: SET_STORES,
            payload: data,
        };
    }

    return {
        type: SET_STORES,
        payload: [],
    };
};






