import React, { useState } from 'react'
import OfferdataTable from '../../Components/OfferdataTable'
import {useSelector} from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Btnwithicon from '../../Components/Btnwithicon';
import {useNavigate} from "react-router-dom";
import Storetable from '../../Components/Tablesdata/Storetable';

const Mainstore = () => {

    const navigate = useNavigate()

    const storeData = useSelector((state)=>state.storeReducer)

    // console.log(storeData)

    const AddofferBtnClk = ()=>{
        navigate('/addstore')
       
    }

    const [findid, setfindid] = useState('')
  
    const filldata = storeData.filter((x) => {
      return findid !== "" ? x.id.toString().includes(findid) || x.cashbackrate.toString().includes(findid) || x.name.toLowerCase().includes(findid) || x.name.includes(findid) : x
    })
  return (
    <div>
        <div style={{display:'flex',justifyContent:'right',margin:'10px 10px'}}>
        
        <Btnwithicon color={'#2a2185'} title={'ADD STORE'} icon={faCirclePlus} onClick={AddofferBtnClk}/>
        </div>

        <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
        <Storetable data={filldata}/>
    </div>
  )
}

export default Mainstore