import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DashCard from '../../Components/DashCard'
import Pagination from '../../Components/Pagination'
import Calendarmy from '../../Components/Shortcom/Calendarmy'
import Clickstable from '../../Components/Tablesdata/Clickstable'


const Mainclicks = ({ propdata }) => {



  const clickData = useSelector((state) => state.clickReducer)

  const [startDate, setStartDate] = useState(null)
const mydate = new Date(startDate)
const todate = mydate.getDate()
let month = mydate.getMonth()
month = month+1
const year = mydate.getFullYear()
const finaldate = `${year}-${month<10?`0${month}`:month}-${todate<10?`0${todate}`:todate}`

 

  const [findid, setfindid] = useState('')

  const filldata = clickData.filter((x) => {

    return  startDate !==null? x.createdAt?.split('T')[0]===finaldate : findid !== "" ? x.id.toString().includes(findid) || x.clickid.toString().includes(findid) : x
  })

  const [currentPage, setCureentPage] = useState(1)
  const [userPerPage, setuserPerPage] = useState(30)
  const [myfilter, setmyfilter] = useState([])


  const indexLastUser = currentPage * userPerPage;
  const indexFirstUser = indexLastUser - userPerPage;
  const currentuser = filldata.slice(indexFirstUser, indexLastUser)


  useEffect(() => {
    setmyfilter(clickData)
  }, [clickData])

  const paginate = (number) => {
    setCureentPage(number)
  }

  const callanydate = (days) => {
    var mysevendays = new Date();
    mysevendays.setDate(mysevendays.getDate() - days);
    return mysevendays.toISOString().split('T')[0]
  }

  // || x.storename.includes(findid) || x.clickid.toString().includes(findid) 

  const todayusers = clickData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
  const yesterdayusers = clickData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
  const lastsevendays = clickData.filter((val) => val?.createdAt?.split('T')[0] >= callanydate(7))
  const lastthirtydays = clickData.filter((val) => val?.createdAt?.split('T')[0] >= callanydate(30))





  return (
    <div>
      <div className='cardBox'>
        <DashCard number={todayusers.length} title='Today Views' icon={'disc-outline'} />
        <DashCard number={yesterdayusers.length} title='Yesterday Views' icon={'disc-outline'} />
        <DashCard number={lastsevendays.length} title='Last 7 Days Views ' icon={'disc-outline'} />
        <DashCard number={lastthirtydays.length} title='Last 30 Days Views ' icon={'disc-outline'} />

      </div>


      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        {/* <Btnwithicon color={'#2a2185'} title={'ADD DEAL/COUPON'} icon={faCirclePlus} onClick={AddofferBtnClk}/> */}
      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID" />
        </label>

        <div style={{cursor:'pointer',zIndex:8}}>
          Date
          <Calendarmy value={startDate} onChange={(e)=>setStartDate(e)}/>
        </div>


      </div>
      <Clickstable data={filldata} />

      {/* <Pagination perPage={userPerPage} totalData={clickData.length} paginate={paginate}/> */}

    </div>
  )
}

export default Mainclicks