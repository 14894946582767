
import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const center = {
    lat: 26.2124007,
    lng: 78.1772053
};

class Googlemap extends Component {
    render() {
        return (
            <div>
                <Map
                    google={this.props.google}
                    initialCenter={center}
                
                    zoom={14}
                    onDragend={(e)=>console.log(e)}
                   
                     >
                        <Marker anchorPoint={center} title={'title'}/>
                     </Map>

                     

                    
                    

            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey:'AIzaSyB7faQv3FK5mymgxKDcnzsDigTAUF7AZ8g'
})(Googlemap)
