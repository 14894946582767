import React, { useState } from 'react'
import OfferdataTable from '../../Components/OfferdataTable'
import { useSelector } from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Btnwithicon from '../../Components/Btnwithicon';
import { useNavigate } from "react-router-dom";
import SendnotifiAll from '../../Components/SendnotifiAll';

const Mainoffer = () => {

  const navigate = useNavigate()

  const offerData = useSelector((state) => state.offerReducer)

  const AddofferBtnClk = () => {
    navigate('/addoffer')

  }

  const [findid, setfindid] = useState('')
  
  const filldata = offerData.filter((x) => {
    return findid !== "" ? x.id.toString().includes(findid) || x.partnerid.toString().includes(findid) || x.userid.toString().includes(findid) || x.txnname.toLowerCase().includes(findid) ||  x.orderid.toLowerCase().includes(findid) : x
  })

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        {/* <Btnwithicon color={'#2a2185'} title={'ADD OFFER'} icon={faCirclePlus} onClick={AddofferBtnClk} /> */}


      </div>

      <div class="search" style={{ display: 'flex', width: '70%' }}>

        <label>
          <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search via Name,ID,Category" />
        </label>

      </div>
      <OfferdataTable data={filldata} />



    </div>
  )
}

export default Mainoffer