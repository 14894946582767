import React from 'react'
import { useState } from 'react'

const Header = ({ settoggle, toggle }) => {

    const userInfo =  JSON.parse(localStorage.getItem('userInfo'))

    const [show, setshow] = useState(false)


    const handlelogout = ()=>{
        localStorage.removeItem('token')
        localStorage.clear()
        alert('admin logout done')
        window.location.reload('/')
    }

    

    return (
        <div>

            <div class="topbar">
                <div onClick={() => settoggle(!toggle)} class="toggle">
                    <ion-icon name="menu-outline"></ion-icon>
                </div>

                <div class="search">
                    <label>
                        <input type="text" placeholder="Search here" />
                        <ion-icon name="search-outline"></ion-icon>
                    </label>
                </div>

                <div onClick={() => setshow(!show)} class="user">
                    <img src="https://res.cloudinary.com/dyueow7yj/image/upload/v1663911731/gwalaxpelogo_lfqeos.png" alt="gwalaxpe" />


                </div>


            </div>


            {show&& <div class="myprofildrop bg-white text-base z-50 list-none divide-y divide-gray-100 rounded shadow my-4">
                <div class="px-4 py-3">
                    <span class="block text-sm">{userInfo?.name}</span>
                    <span class="block text-sm font-medium text-gray-900 truncate">{userInfo?.email}</span>
                </div>
                <ul class="py-1" aria-labelledby="dropdown">
                    <li>
                        <a href="#" class=" text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Profile</a>
                    </li>
                   
                    <li>
                        <a  onClick={handlelogout} class=" myaaaa text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Sign out</a>
                    </li>
                </ul>
            </div>}
        </div>

    )
}

export default Header