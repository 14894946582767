import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Mybtn from '../../Components/Mybtn'
import Textcompo from '../../Components/Shortcom/Textcompo'
import Pmclaimedtable from '../../Components/Tablesdata/Pmclaimedtable'

const Viewpmcode = () => {

    const { id } = useParams()
    const userData = useSelector((state) => state.promoReducer).filter((val) => val.id == id)[0]

    // console.log(userData)

    const genratedate = (datee) => {
        const mydate = new Date(datee)
        const todate = mydate.getDate()
        const month = mydate.getMonth()
        const year = mydate.getFullYear()
        var hours = mydate.getHours();
        var minutes = mydate.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return `${todate}-${month}-${year} ${hours}:${minutes} ${ampm}`
    }

    return (
        <div >

            <p style={{ fontSize: 25, fontWeight: '500' }}>Promocode View</p>




            <div className='viewpromocls' >
                <div className='profilecard1'  >

                    <div style={{ display: 'block', justifyContent: 'center', }}>





                    </div>

                    <Textcompo text={'ID'} subtext={id} bgcolor={'#00a65a'} />
                    <Textcompo text={'Promo name'} subtext={userData?.name} bgcolor={'#00a65a'} />
                    <Textcompo text={'Promocode'} subtext={userData?.code} bgcolor={'#00a65a'} />
                    <Textcompo text={'Amount'} subtext={`₹ ${userData?.amount}`} bgcolor={'#00a65a'} />
                    <Textcompo text={'Total Codes'} subtext={userData?.alltotals} bgcolor={'#00a65a'} />
                    <Textcompo text={'Active Codes'} subtext={userData?.total} bgcolor={'#00a65a'} />
                    <Textcompo text={'Claimed users'} subtext={userData?.claimedusers?.length} bgcolor={'#e65b32'} />
                    <Textcompo text={'Created Date'} subtext={genratedate(userData?.createdAt)} bgcolor={'#e65b32'} />
                    <Textcompo text={'Expired Date'} subtext={genratedate(userData?.expireat)} bgcolor={'#e65b32'} />


                    <Textcompo text={'Is Active'} subtext={userData?.isactive ? 'TRUE' : 'FALSE'} bgcolor={'#e65b32'} />



                    {/* <Mybtn title={'Add Payment'} bgcolor={'#3c8dbc'} color={'white'} onClick={addpayment} /> */}




                </div>


                <div>

                    <h4>Claimed Users Details</h4>
                    <Pmclaimedtable data={userData?.claimedusers} code={userData?.code} />
                </div>
            </div>
        </div>
    )
}

export default Viewpmcode