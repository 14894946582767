


import { faCirclePlus, faRemove, faCircleDown } from '@fortawesome/free-solid-svg-icons'
import { TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Textarea } from 'react-rainbow-components'
import { useParams } from 'react-router-dom'
import gpeapi from '../../api/gpeapi'
import Btnwithicon from '../../Components/Btnwithicon'
import Fileselecotorcom from '../../Components/Fileselecotorcom'
import Inputcom from '../../Components/Inputcom'
import Popupnoti from '../../Components/Popupnoti'
import Radiobtncom from '../../Components/Radiobtncom'
import Textareacom from '../../Components/Textareacom'
import Tongglecom from '../../Components/Tongglecom'
import { useDispatch, useSelector } from 'react-redux'
import { fethselecteddeal} from '../../redux/actions/selectedaction'
import Selectcom from '../../Components/Selectcom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Mybtn from '../../Components/Mybtn'
import Select from 'react-select'
import Latselct from '../../Components/Latselct'
import Selcetlat from '../../Components/Selcetlat'
import { fethdeals } from '../../redux/actions/dealaction'

const Adddeal = () => {

    const { id } = useParams()

    const dispatch = useDispatch()

    const state5 = useSelector((state) => state.selectedReducer)
    const categoryData = useSelector((state) => state.categoryReducer)
    const storeData = useSelector((state) => state.storeReducer)

    



    const token = localStorage.getItem('token')

    const [notidata, setnotidata] = useState({
        msg: '',
        success: '',
        show: false
    })
    const options = []

    const optionstoreid = []

    const updateoption = []
    const updateoptionstore =[ ]

    const [mycategory, setmycategory] = useState([])

    const [formValues, setFormValues] = useState([{ rate: "", description: "" }])

    const [mydta, setmydta] = useState({
        name: '',
        icon: '',
        storeid:'',
        cashbackrate: '',
        cashbacktype: '',
        sortdes: '',
        fulldes: '',
        url: '',
        category: '',
        isactive: '',
        ispopular: '',
        keypoints: '',
        expiredat: '',
        brand:'',
        mrprate:'',
        sellrate:'',
        isdeal:'',
        couponcode:'',
        dealtype:'',
        cashbacktypeon:''
        
    })


    
    const handleChnage = (e) => {
        const name = e.target.name
        const value = e.target.value

        setmydta({ ...mydta, [name]: value })
    }

    const callingAPI = () => {
        dispatch(fethselecteddeal(id))
    }

   

    const updateForm = async () => {

        const userData = await state5.filter((val) => val.id == id)
        userData.map((val) => setmydta(val))
        userData.map((val) => setFormValues(val.allcashbackrates))
        
       
    }

    
    const storedtdtd =  storeData.filter((val)=>val.id==mydta.storeid)
    storedtdtd.map((val)=>updateoptionstore.push({label:val.name,value:val.id}))
 
 

    

    const categorydta = mydta.category
    for (let i = 0; i < categorydta.length; i++) {
        updateoption.push({ label: categorydta[i], value: categorydta[i] })
        // console.log({label:categorydta[i],value:categorydta[i]})
    }

    



    // console.log(updateoption)


    const handlBtnClk = () => {


        id ? gpeapi.put(`/updatdeal/${id}`, mydta, {
            headers: {
                "Authorization": `Beaer ${token}`
            }
        }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))
            : gpeapi.post('/adddeal', mydta, {
                headers: {
                    "Authorization": `Beaer ${token}`
                }
            }).then((res) => setnotidata({ msg: res.data.msg, show: true, success: res.data.success })).catch((e) => console.log(e))

        // console.log(mydta)
    }


    // console.log(state5[0])
    const match = state5[0]?.id == id

    useEffect(() => {
        id && callingAPI()
        updateForm()
    }, [match, id])


    const setcategoryyy = async () => {
        const allCategoryName = await categoryData.map((val) => options.push({ label: val.name, value: val.name }))
        await storeData.map((val)=>optionstoreid.push({label:val.name,value:val.id}))


    }

    useEffect(() => {
        setcategoryyy()
    }, [options])



    const handlecategoryselect = (e) => {

        const categoryev = e?.map((val) => val.value)
        setmydta({ ...mydta, category: categoryev })
    }

    const handlestoreselect = (e) => {
        updateoptionstore.push({label:e.label,value:e.value})
        setmydta({...mydta,storeid:e.value}) 
    }



    

    

    return (
        <div style={{ width: '90%', marginTop: 30 }}>




            <Inputcom label={'Deal Name'} placeholder={'Please Enter Deal Name'} name='name' value={mydta.name} onChange={handleChnage} />


            <Selcetlat label={'Store Name'} data={optionstoreid}  name={'storeid'} onChange={handlestoreselect} value={updateoptionstore[0]}/>



            <Selectcom label={'Cashback Type FLAT or UPTO '} data={['FLAT', 'UPTO']} name='cashbacktype' onChange={handleChnage} value={mydta.cashbacktype} />
            
            <Selectcom label={'Cashback Type ₹/% '} data={['₹', '%']} name='cashbacktypeon' onChange={handleChnage} value={mydta.cashbacktypeon} />

            <Inputcom label={'Deal Cashback'} placeholder={'Please Enter Deal Cashback Rate'} name='cashbackrate' value={mydta.cashbackrate} onChange={handleChnage} />

            <Selectcom label={'Deal Type '} data={['COUPON', 'DEAL']} name='dealtype' onChange={handleChnage} value={mydta.dealtype} />

            {
                mydta.dealtype=="COUPON" && <Inputcom label={'Coupon Code'} placeholder={'Please Enter Coupon Code'} name='couponcode' value={mydta.couponcode} onChange={handleChnage} />
            }


            <Inputcom label={'Deal Url'} placeholder={'Please Enter Deal URL'} name='url' value={mydta.url} onChange={handleChnage} />

            <Inputcom label={'Brand Name'} placeholder={'Please Enter Brand Name'} name='brand' value={mydta.brand} onChange={handleChnage} />

            <Inputcom label={'MRP Rate'} placeholder={'Please Enter MRP Rate'} name='mrprate' value={mydta.mrprate} onChange={handleChnage} />

            <Inputcom label={'SELL Rate'} placeholder={'Please Enter SELL Rate'} name='sellrate' value={mydta.sellrate} onChange={handleChnage} />


            <Latselct label={'Deal Category'} data={options} onChange={handlecategoryselect} name={'category'} value={updateoption} />



            <Inputcom label={'Deal Icon Url'} placeholder={'Please Enter Deal icon URL'} name='icon' value={mydta.icon} onChange={handleChnage} />

            {<div className='flex justify-center  '>
                <img className='bg-red-200 rounded-md' src={mydta.icon ? mydta.icon : 'https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png'} style={{ width: 300, height: 100, padding: 3 }} />
            </div>}

            <Textareacom label='keypoints Deal' placeholder='Please Enter Keypoints this Deal' name='keypoints' value={mydta.keypoints} onChange={handleChnage} />

            <Inputcom label={'Offer Sort Description'} placeholder={'Please Enter sort description'} name='sortdes' value={mydta.sortdes} onChange={handleChnage} />



            <Textareacom label='Full Steps' placeholder='Please Enter All Steps' name='fulldes' value={mydta.fulldes} onChange={handleChnage} />


            <Selectcom label={'is Deal'} data={['true', 'false']} name='isdeal' onChange={handleChnage} value={mydta.isdeal} />

            <Selectcom label={'is Active'} data={['true', 'false']} name='isactive' onChange={handleChnage} value={mydta.isactive} />

            <Selectcom label={'is Popular'} data={['true', 'false']} name='ispopular' onChange={handleChnage} value={mydta.ispopular} />


            




            {/* <Inputcom label={'Deal Expire Date'} placeholder={'Please Enter Deal Expire Date'} name='expiredat' value={mydta.expiredat} onChange={handleChnage} /> */}

            <input type={'date'} value={mydta.expiredat} name='expiredat' onChange={handleChnage}/>


            <Popupnoti show={notidata.show} msg={notidata.msg} success={notidata.success} direction={'/deals'} dispatchfun={fethdeals}/>

            <div style={{ margin: '60px 0px' }}><Btnwithicon onClick={handlBtnClk} color={'white'} title={id ? 'UPDATE DEAL' : 'ADD DEAL'} icon={faCirclePlus} width={'950px'} bgcolor={'#2a2185'} /></div>








        </div>
    )
}

export default Adddeal