import React from 'react'
import Datatable from '../../Components/Datatable'
import Newdatatable from '../../Components/Newdatatable'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import Btnwithicon from '../../Components/Btnwithicon'
import { useState } from 'react'
import Pagination from '../../Components/Pagination'
import { useEffect } from 'react'
import DashCard from '../../Components/DashCard'
import Calendarmy from '../../Components/Shortcom/Calendarmy'


const Usermain = () => {

  const userData = useSelector((state) => state.userReducer).filter((val)=>val.role!=='partner' )

  const [findid, setfindid] = useState('')

  const [startDate, setStartDate] = useState(null)
  const mydate = new Date(startDate)
  const todate = mydate.getDate()
  let month = mydate.getMonth()
  month = month+1
  const year = mydate.getFullYear()
  const finaldate = `${year}-${month<10?`0${month}`:month}-${todate<10?`0${todate}`:todate}`

  const [findidonly, setfindidonly] = useState('')
  const [findrefercode, setfindrefercode] = useState('')

  const filldata = userData.filter((x) => {
    return startDate !==null? x.createdAt?.split('T')[0]===finaldate : findidonly!==""?  x.id.toString()==findidonly : findid !== "" ? x.id.toString().includes(findid) || x.email.includes(findid) || x.mobile.toString().includes(findid) || x.name.toLowerCase().includes(findid) ||  x?.deviceuid?.includes(findid) || x.name.includes(findid) || x.refercode.includes(findid) : x
  })




  

 


 




  const navigate = useNavigate()

  const AddofferBtnClk = () => {
    navigate('/adduser')

  }





  const callanydate = (days) => {
    var mysevendays = new Date();
    mysevendays.setDate(mysevendays.getDate() - days);
    return mysevendays.toISOString().split('T')[0]
  }


 
  


  const todayusers = userData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(0))
  const yesterdayusers = userData.filter((val) => val?.createdAt?.split('T')[0] == callanydate(1))
  const lastsevendays = userData.filter((val)=>val?.createdAt?.split('T')[0]>=callanydate(7))
  const lastthirtydays = userData.filter((val)=>val?.createdAt?.split('T')[0]>=callanydate(30))

  // console.log(lastsevendays)


  return (
    <div>
      <div className='cardBox'>
        <DashCard number={todayusers.length} title='Today Users' icon={'person-outline'} />
        <DashCard number={yesterdayusers.length} title='Yesterday Users' icon={'person-outline'} />
        <DashCard number={lastsevendays.length} title='Last 7 Days Users ' icon={'person-outline'}/>
        <DashCard number={lastthirtydays.length} title='Last 30 Days Users ' icon={'person-outline'}/>

      </div>

      <div style={{ display: 'flex', justifyContent: 'right', margin: '10px 10px' }}>

        <Btnwithicon color={'#2a2185'} title={'ADD PARTNER'} icon={faCirclePlus} onClick={AddofferBtnClk} />
      </div>

      <div>

        <div class="search" style={{display:'flex',width:'70%'}}>
          <label>
            <input type="text" value={findidonly}  onChange={(e) => setfindidonly(e.target.value)} placeholder="Search UserID only" />
           

          </label>

          <label>
            <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search User Here" />
           
          </label>

          
        <div style={{cursor:'pointer',zIndex:8}}>
          Date
          <Calendarmy value={startDate} onChange={(e)=>setStartDate(e)}/>
        </div>
{/* 
          <label>
            <input type="text" value={findid} onChange={(e) => setfindid(e.target.value)} placeholder="Search Refer Code" />
           
          </label> */}
        </div>
      </div>



      <Datatable data={filldata} />

      



    </div>
  )
}

export default Usermain