export const SET_USERS = 'SET_USERS';
export const SET_OFFERS = 'SET_OFFERS';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_BASIC = 'SET_BASIC';
export const SET_STORES = 'SET_STORES';
export const SET_DEALS = 'SET_DEALS';
export const SET_CATEGORYS = 'SET_CATEGORYS';
export const SET_CLICKS = 'SET_CLICKS';
export const SET_PROMO = 'SET_PROMO';
export const SET_CLONED = 'SET_CLONED';



export const UPDATE_OFFERS = 'UPDATE_OFFERS';
export const SET_TXN = 'SET_TXN';
export const SET_WITXN = 'SET_WITXN';
export const ADD_TXN = "ADD_TXN";
export const SET_PERUSER = "SET_PERUSER";
export const SELECTED_TXN = "SELECTED_TXN";
export const SELECTED_OFFER = "SELECTED_OFFER";
export const SELECTED_STORE = "SELECTED_STORE";
export const SELECTED_DEAL = "SELECTED_DEAL";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const SELECTED_USER = "SELECTED_USER";
export const SELECTED_BANNER = "SELECTED_BANNER";
export const SELECTED_BASIC = "SELECTED_BASIC";
export const UPDATE_SELECTED_TXN = "UPDATE_SELECTED_TXN";
export const SELECTED_PROMO = 'SELECTED_PROMO'
