import React from 'react'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'


const LineChartsDash = () => {
    const UserData = useSelector((state) => state.userReducer)
    const TxnData = useSelector((state) => state.txnReducer)
    const WithData = useSelector((state) => state.witxnReducer)
    const clickData = useSelector((state) => state.clickReducer)
    const BookinData = useSelector((state) => state.offerReducer)





    const dedectMonth = (datee)=>{
        var mysevendays = new Date(datee);
        return `${mysevendays.getMonth()+1}${mysevendays.getFullYear()}`
    }



    const myDta = []
    const myPdta =[]
    const txnDta = []
    // const WithDta = []
    const ActivDta = []
const Bookdta = []
    const ClkDta = []

    for(let i=1;i<13;i++){
       const MonthLen = UserData.filter((val)=> val.role=='user' && dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
       const PartMonthLen = UserData.filter((val)=> val.role=='partner' && dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
       const MonthTxn = TxnData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
    //    const WithMonthTxn = WithData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
       const ActiveMonthuser = UserData.filter((val)=>dedectMonth(val.lastlogin)==`${i}${new Date().getFullYear()}`)

       const ClickMonthuser = clickData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)
       const BookingMonth = BookinData.filter((val)=>dedectMonth(val.createdAt)==`${i}${new Date().getFullYear()}`)



       ActivDta.push(ActiveMonthuser.length)
        myDta.push(MonthLen.length)
        myPdta.push(PartMonthLen.length)
        txnDta.push(MonthTxn.length)
        // WithDta.push(WithMonthTxn.length)
        ClkDta.push(ClickMonthuser.length)
        Bookdta.push(BookingMonth.length)

    }

    // console.log(ActivDta)






  return (
    <div>

        <Chart type='line' height={300} 
        series={[
            {name:'Users',data:myDta},
            {name:'Partners',data:myPdta},
            {name:'Bookings',data:Bookdta,color:'#0ecca6'},
            {name:'Transaction',data:txnDta,color:'#384563'},
            // {name:'Withdrawal',data:WithDta},
            {name:'Active ',data:ActivDta,color:'#1e5c2a'},
            {name:'Views ',data:ClkDta,color:'#9c1c7c'},
        ]}
        options={{
            title:{text:`Analytics This Year ${new Date().getFullYear()}`},
            xaxis:{
                // title:{text:"Users in This Year"},
                categories:['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
            }
        }}
        >

        </Chart>

    </div>
  )
}

export default LineChartsDash