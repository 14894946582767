

import React from 'react'
import OfferdataTable from '../../Components/OfferdataTable'
import {useSelector} from 'react-redux'
import { Button } from 'react-rainbow-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Btnwithicon from '../../Components/Btnwithicon';
import {useNavigate} from "react-router-dom";
import Storetable from '../../Components/Tablesdata/Storetable';
import Categorytable from '../../Components/Tablesdata/Categorytable';

const Maincategory = () => {

    const navigate = useNavigate()

    const caretegoryData = useSelector((state)=>state.categoryReducer)

    // console.log(storeData)

    const AddofferBtnClk = ()=>{
        navigate('/addcategory')
       
    }
  return (
    <div>
        <div style={{display:'flex',justifyContent:'right',margin:'10px 10px'}}>
        
        <Btnwithicon color={'#2a2185'} title={'ADD CATEGORY'} icon={faCirclePlus} onClick={AddofferBtnClk}/>
        </div>
        
        <Categorytable data={caretegoryData}/>
    </div>
  )
}

export default Maincategory