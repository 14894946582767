import React from 'react'


const Inputcom = ({label,placeholder,onChange,value,name,type}) => {

    const containerStyles = {
        maxWidth: 950,
        margin:'25px 10px',
        borderRadius:5
       
    };
  return (


<div class="mb-6" style={{position:'relative'}}>
    <label htmlFor="default-input" class="block mb-2 text-sm text-left font-medium text-gray-900 dark:text-gray-300">{label}</label>
    <input name={name} type={type || 'text'} placeholder={placeholder} value={value==null?'':value} onChange={onChange} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
</div>
  )
}

export default Inputcom